import {
  ChangeEvent,
  MouseEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { t } from "i18next";
import Paper from "@mui/material/Paper";

import TableRow from "@mui/material/TableRow";
import {
  Box,
  Unstable_Grid2 as Grid,
  TableSortLabel,
  Table,
  TablePagination,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import SearchInput from "../../components/common/SearchInput";
import NoData from "../../components/common/NoData";
import moment from "moment";
import { Severity } from "../../constants";
import { History } from "@/app/types";
import { DocumentNode, useQuery } from "@apollo/client";
import { AppContext } from "../../context/AppContext";
import { getDateValue } from "../../utils/getDateValue.util";

interface Column {
  id:
    | "createdAt"
    | "type"
    | "description"
    | "transaction"
    | "reservation"
    | "status"
    | "success"
    | "transcript";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value?: number | string) => string;
}

const columns: readonly Column[] = [
  { id: "createdAt", label: t("history.date"), minWidth: 170 },
  {
    id: "description",
    label: t("history.description"),
    minWidth: 70,
  },
  {
    id: "type",
    label: t("history.type"),
    minWidth: 70,
  },
  {
    id: "transcript",
    label: t("history.transcript"),
    minWidth: 70,
  },
  {
    id: "transaction",
    label: t("history.value"),
    minWidth: 70,
  },
  {
    id: "reservation",
    label: t("history.value"),
    minWidth: 70,
  },
  {
    id: "status",
    label: t("history.status"),
    minWidth: 70,
  },
  {
    id: "success",
    label: t("history.success"),
    minWidth: 100,
  },
];

enum Order {
  ASC = "asc",
  DESC = "desc",
}

enum RowFields {
  DATE = "createdAt",
  SUCCESS = "success",
  TYPE = "type",
  STATUS = "status",
  TRANSACTION = "transaction",
  DESCRIPTION = "description",
  TRANSCRIPT = "transcript",
  RESERVATION = "reservation",
}

function descendingComparator(a: History, b: History, orderBy: RowFields) {
  if (b[orderBy]! < a[orderBy]!) {
    return -1;
  }

  if (b[orderBy]! > a[orderBy]!) {
    return 1;
  }

  return 0;
}

function getComparator(order: Order, orderBy: RowFields) {
  return order === Order.DESC
    ? (a: History, b: History) => descendingComparator(a, b, orderBy)
    : (a: History, b: History) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: History[], comparator: Function) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }

    switch (typeof a[1]) {
      case "number":
        return a[1] - Number(b[1]);
      case "string":
        return String(a[1]).localeCompare(b[1].toString());
      default:
        return a[1] < b[1] ? -1 : 1;
    }
  });
  return stabilizedThis.map((el) => el[0]);
}

export enum HistoryType {
  TRANSACTION = "TRANSACTION",
  MEMBER = "MEMBER",
  RESERVATION = "RESERVATION",
}

const QueryKeys = {
  [HistoryType.TRANSACTION]: "transactionForMember",
  [HistoryType.RESERVATION]: "activityForReservation",
  [HistoryType.MEMBER]: "activityForMember",
};
export default function MemberHistory({
  uuid,
  query,
  type,
}: {
  uuid: string;
  query: DocumentNode;
  type: HistoryType;
}) {
  const key = QueryKeys[type];
  const { setSnackBarMessageProps } = useContext(AppContext);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState(Order.ASC);
  const [orderBy, setOrderBy] = useState<RowFields | null>(null);
  const [rows, setRows] = useState<History[]>([]);
  const [searchText, setSearchText] = useState("");
  const { data, refetch, loading } = useQuery<{
    [key: string]: { items: History[]; count: number };
  }>(query, {
    variables: {
      uuid,
      limit: 10,
      offset: 0,
    },
  });

  const historyData = data?.[key];
  const count = historyData?.count ?? 0;
  useEffect(() => {
    if (historyData && !loading) {
      setRows(historyData.items);
    }
  }, [historyData, loading]);

  const handleChangePage = async (event: unknown, newPage: number) => {
    refetchData(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const limit = parseInt(event.target.value, 10);
    return refetchData(limit, 0);
  };

  const refetchData = async (limit: number, page: number) => {
    try {
      setIsLoading(true);
      await refetch({
        uuid,
        limit,
        offset: page * limit,
      });
      setPage(page);
      setRowsPerPage(limit);
    } catch (error) {
      setSnackBarMessageProps?.({
        severity: Severity.Error,
        message: t("common.error"),
      });

      console.error(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };
  const createSortHandler =
    (property: RowFields) => (event: MouseEvent<HTMLAnchorElement>) => {
      const isAsc = orderBy === property && order === Order.ASC;
      setOrder(isAsc ? Order.DESC : Order.ASC);
      setOrderBy(property);
    };

  const visibleRows = (
    useMemo(
      () => stableSort(rows, getComparator(order, orderBy!)),
      [order, orderBy, rows],
    ) as History[]
  ).filter((row) => {
    return (
      !searchText ||
      new RegExp(searchText, "ig").test(
        `${t(`history.event-${row.event}`)} ${row.description} ${row.reservation?.credit?.amount ?? ""}` +
          `${row.reservation?.status ?? ""} ${row.transaction.amount ?? ""}  ${row.transaction.type ?? ""}` +
          `${t(`history.success-${row.success}`)}`,
      )
    );
  });

  const historyColumns = columns.filter((i) => {
    switch (type) {
      case HistoryType.MEMBER:
        return !["reservation", "transcript"].includes(i.id);
      case HistoryType.RESERVATION:
        return !["transaction", "transcript"].includes(i.id);
      case HistoryType.TRANSACTION:
        return ![
          "reservation",
          "transaction",
          "status",
          "description",
        ].includes(i.id);
      default:
        return true;
    }
  });
  const tableContent = (
    <Paper>
      <TableContainer sx={{ maxHeight: "60vh" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {historyColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sortDirection={orderBy === column.id ? order : false}
                  style={{ minWidth: column.minWidth }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : Order.ASC}
                    onClick={createSortHandler(column.id as RowFields)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === Order.DESC
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row, i) => {
              return (
                <TableRow hover role="button" tabIndex={-1} key={i}>
                  {historyColumns.map((column) => {
                    switch (column.id) {
                      case RowFields.DATE:
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {moment(getDateValue(row[column.id])).format(
                              "hh:mm A MMM DD, YYYY",
                            )}
                          </TableCell>
                        );
                      case RowFields.SUCCESS:
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {t(`history.success-${row[column.id]}`)}
                          </TableCell>
                        );
                      case RowFields.DESCRIPTION:
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {row.description ?? "—"}
                          </TableCell>
                        );
                      case RowFields.TRANSACTION:
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {row.transaction?.amount && "$"}{" "}
                            {row.transaction?.amount ?? "—"}
                          </TableCell>
                        );
                      case RowFields.RESERVATION:
                        return (
                          <TableCell key={column.id} align={column.align}>
                            $ {row.reservation?.credit?.amount ?? 0}
                          </TableCell>
                        );
                      case RowFields.TYPE:
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {row.type ?? row.transaction?.type ?? "—"}
                          </TableCell>
                        );
                      case RowFields.TRANSCRIPT:
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {row.transcript ?? "—"}
                          </TableCell>
                        );
                      case RowFields.STATUS:
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {row.reservation?.status ?? "—"}
                          </TableCell>
                        );
                      default:
                        return (
                          <TableCell key={column.id} align={column.align}>
                            —
                          </TableCell>
                        );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {visibleRows.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          disabled={isLoading}
          rowsPerPage={rowsPerPage}
          page={page}
          translate="yes"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );

  return (
    <div>
      <Box>
        <Grid container alignItems="center">
          <Grid sx={{ mb: 1 }} xs={12} md={9}></Grid>
          <Grid xs={12} md={3} sx={{ my: { xs: 2 } }}>
            <SearchInput
              placeholder={t("common.search")}
              change={setSearchText}
            ></SearchInput>
          </Grid>
        </Grid>
        {tableContent}
        {visibleRows.length === 0 && (
          <NoData topMargin="10px" title={t("members.noActivityTitle")} />
        )}
      </Box>
    </div>
  );
}
