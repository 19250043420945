import { SetStateAction, SyntheticEvent, useContext, useState } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import Drawer from "@mui/material/Drawer";
import {
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { Restaurant } from "../../types/Restaurant";
import { CurrencySymbol, UIMode } from "../../constants";
import { AppContext } from "../../context/AppContext";
import PageSpinner from "../../components/common/PageSpinner";
import { Link } from "react-router-dom";
import { Instagram } from "@mui/icons-material";
import { Gallery, MenuCategory } from "@/app/types";

function CustomTabPanel(props: {
  children: React.ReactNode;
  value: number;
  index: number;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 2, px: 1 }}>{children}</Box>}
    </div>
  );
}

const CustomTab: any = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    fontSize: theme.typography.pxToRem(10),
    marginRight: theme.spacing(1),
    padding: 0,
    minWidth: 40,
    "&.Mui-selected": {},
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  }),
);

const CustomTabs: any = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    paddding: 0,
  },
});

const MobilePreview = ({
  restaurant,
  bgColor,
  gallery,
  categories,
}: {
  restaurant: Restaurant;
  gallery: Gallery;
  bgColor: string;
  categories?: MenuCategory[];
}) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const [categoryIndex, setCategoryIndex] = useState(0);
  const handleChange = (event: SyntheticEvent, tab: number) => {
    setTab(tab);
  };

  const { data: photo } = gallery?.primary ?? {};
  const textStyle = { fontSize: 10 };
  const tabs = [
    {
      id: 1,
      title: t("preview.aboutTab"),
      disabled: false,
    },
    {
      id: 2,
      title: t("preview.menuTab"),
      disabled: false,
    },
    {
      id: 3,
      title: t("preview.location"),
      disabled: false,
    },
    {
      id: 4,
      title: t("preview.arrival"),
      disabled: false,
    },
    {
      id: 5,
      title: t("preview.cancellation"),
      disabled: false,
    },
  ];

  const { city } = restaurant;
  const mapSrc =
    `https://www.google.com/maps/embed/v1/place?zoom=17&key=AIzaSyCpuAUtVX4Zhix_7XIiQZECbRX-r2s7h60&` +
    `q=${encodeURIComponent(city.addressLine1 + "," + (city.name ?? "") + "," + (city.location ?? "") + "," + t(`countries.${city.country}`))}`;
  const longestCategoryName =
    categories?.reduce(
      (prev, current) =>
        prev >= current.title.length ? prev : current.title.length,
      0,
    ) ?? 10;

  const menuWidth = (categories?.length ?? 20) * longestCategoryName * 5;
  const menuItems = (categories?.[categoryIndex]?.items ?? []).filter(
    (item) => item.name,
  );
  return (
    <Box bgcolor={bgColor} height="100%">
      <div>
        {photo ? (
          <div
            className="w-full"
            style={{
              height: "240px",
              background: `url(${photo}) center no-repeat `,
              backgroundSize: "cover",
            }}
          ></div>
        ) : (
          <Box>
            <Typography className="pt-[50%] h-[254px] text-center">
              {t("preview.noPhoto")}
            </Typography>
          </Box>
        )}
      </div>
      <Box marginY={2} marginX={1} position="relative">
        <Box>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body2">{restaurant.name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <div className="text-right px-2">
                {restaurant.contact?.instagram && (
                  <Link
                    target="_blank"
                    to={`https://instagram.com/${restaurant.contact?.instagram}`}
                  >
                    <Instagram fontSize="small" />
                  </Link>
                )}
              </div>
            </Grid>
          </Grid>
          <div className="flex gap-1 mt-1">
            <Typography sx={textStyle} variant="body2">
              {[restaurant.city?.name, restaurant.city?.location]
                .filter(Boolean)
                .join(", ")}{" "}
              {restaurant.city?.country &&
                t(`countries.${restaurant.city.country}`)}
            </Typography>
            <Typography sx={textStyle} variant="caption">
              &middot;
            </Typography>
            <Typography sx={textStyle} variant="body2">
              {restaurant.cuisine || t("common.notAvailable")}
            </Typography>
            <div className="flex-1"> </div>
          </div>
        </Box>
        <Box sx={{ pt: 3, px: 1 }}>
          <Button variant="outlined" fullWidth>
            {t("preview.customInquiry")}
          </Button>
        </Box>
        <Box>
          <CustomTabs
            onChange={handleChange}
            value={tab}
            aria-label={t("preview.title")}
          >
            {tabs.map((tab, index) => (
              <CustomTab
                label={tab.title}
                key={index}
                id={`simple-tab-${index}`}
                disabled={tab.disabled}
                aria-controls={`simple-tabpanel-${index}`}
              />
            ))}
          </CustomTabs>
        </Box>
        <Box>
          <CustomTabPanel value={tab} index={0}>
            <Typography variant="body2" mb={1} style={textStyle}>
              {restaurant.details.description.substring(0, 200)}
              {restaurant.details.description.length > 200 ? "..." : ""}
            </Typography>
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={1}>
            <Box>
              {!categories && (
                <Typography variant="body2">
                  {t("preview.menuPlaceholder")}
                </Typography>
              )}
            </Box>
            <div
              style={{
                overflowX: "scroll",
              }}
            >
              <div
                style={{
                  overflowX: "visible",
                  minWidth: `${menuWidth}%`,
                }}
              >
                {categories?.map((category, index) => (
                  <Chip
                    key={category.uuid}
                    variant={categoryIndex === index ? "filled" : "outlined"}
                    onClick={() => setCategoryIndex(index)}
                    sx={{ mr: 1 }}
                    size="small"
                    label={category.title}
                  />
                ))}
              </div>
            </div>
            <Divider sx={{ marginTop: 2 }} />
            <Box
              sx={{
                overflowY: "scroll",
                height: 120,
                pb: 2,
              }}
            >
              <Box>
                {menuItems.map((item, index) => (
                  <Box key={item.uuid} sx={{ padding: 1 }}>
                    <div className="flex">
                      <Typography className="flex-1" fontSize={12}>
                        {index + 1}. {item.name}
                      </Typography>
                      <Typography fontSize={10}>
                        {CurrencySymbol[item.price.currency]}{" "}
                        {item.price.amount}
                      </Typography>
                    </div>
                    <Typography fontSize={9}>{item.description}</Typography>
                  </Box>
                ))}
              </Box>
              <Box className="text-center">
                {menuItems.length === 0 && (
                  <Typography variant="body2" className="p-6">
                    {t("preview.noMenuItems")}
                  </Typography>
                )}
              </Box>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={2}>
            <div className="relative">
              <iframe
                loading="lazy"
                width="100%"
                height="150"
                src={mapSrc}
                title="map"
                key="map"
              ></iframe>
              <div className="w-full bg-[rgba(0,0,0,0.8)] text-white py-2 px-4 absolute bottom-0  z-10 left-0 right-0 text-[10px]">
                {city.addressLine1 ?? ""}, {city.name ?? ""},{" "}
                {city.location ?? ""}
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={3}>
            <Typography variant="body2" mb={1} style={textStyle}>
              {restaurant.details.arrivalPolicy ?? "—"}
            </Typography>
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={4}>
            <Typography variant="body2" mb={1} style={textStyle}>
              {restaurant.details.cancellationPolicy ?? "—"}
            </Typography>
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  );
};
export default function Preview({
  isOpen,
  setIsOpen,
  restaurant,
  gallery,
}: {
  isOpen: boolean;
  setIsOpen: (value: SetStateAction<boolean>) => void;
  restaurant: Restaurant;
  gallery: Gallery;
}) {
  const { t } = useTranslation();
  const { uiMode, categories } = useContext(AppContext);
  const toggleDrawer = (newOpen: SetStateAction<boolean>) => () => {
    setIsOpen(newOpen);
  };

  const phoneBgColor = uiMode === UIMode.Light ? "#fff" : "#000";
  const width = window.innerWidth < 550 ? window.innerWidth - 20 : 550;
  const DrawerContent = (
    <Box sx={{ width }}>
      <Container sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="right">
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Typography variant="h6">{t("preview.title")}</Typography>
        <Typography my={1} variant="body2">
          {t("preview.description")}
        </Typography>
        <Box
          width={284}
          height={603}
          padding={1}
          boxShadow="1px 2px 10px #333"
          borderRadius={10}
          margin="40px auto 0"
          bgcolor="#000"
        >
          <Box
            border="1px solid #333"
            height="100%"
            width="100%"
            borderRadius={8}
            position="relative"
            sx={{ overflow: "hidden" }}
          >
            <MobilePreview
              restaurant={restaurant}
              categories={categories}
              bgColor={phoneBgColor}
              gallery={gallery}
            />
            <Box width="100%" position="absolute" top={0}>
              <Box
                width={80}
                margin="5px auto"
                borderRadius={5}
                bgcolor="#000"
                height={20}
              ></Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );

  return (
    <div>
      <Drawer open={isOpen} onClose={toggleDrawer(false)} anchor="right">
        {restaurant ? DrawerContent : <PageSpinner />}
      </Drawer>
    </div>
  );
}
