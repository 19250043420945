import moment from "moment";
import { SetStateAction, useContext, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import {
  Button,
  Container,
  Typography,
  Unstable_Grid2 as Grid,
  IconButton,
} from "@mui/material";

import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { Member } from "@/app/types/Member";
import { AppContext } from "../../context/AppContext";
import { Severity } from "../../constants";

export default function MemberApplication({
  isOpen,
  setIsOpen,
  member,
}: {
  isOpen: boolean;
  setIsOpen: (value: SetStateAction<boolean>) => void;
  member: Member;
}) {
  const { t } = useTranslation();
  const { setSnackBarMessageProps } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const toggleDrawer = (newOpen: SetStateAction<boolean>) => () => {
    setIsOpen(newOpen);
  };

  const handleApprove = () => {
    setIsLoading(true);
    setTimeout(() => {
      setSnackBarMessageProps?.({
        message: "Not implemented",
        severity: Severity.Success,
      });
      setIsLoading(false);
    }, 1000);
  };

  const handleReject = () => {
    setIsLoading(true);
    setTimeout(() => {
      setSnackBarMessageProps?.({
        message: "Not implemented",
        severity: Severity.Error,
      });
      setIsLoading(false);
    }, 1000);
  };

  const width = window.innerWidth < 550 ? window.innerWidth - 20 : 550;
  const DrawerContent = (
    <Box sx={{ width }} role="presentation">
      <Container sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid xs={12} textAlign="right">
            <IconButton onClick={toggleDrawer(false)}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Box mt={2}>
          <Typography variant="h5">{t("members.applicationReview")}</Typography>
          <Typography variant="body2">
            {t("members.applicationReviewText")}
          </Typography>
          <Box>
            <Box className="flex gap-4 my-6">
              <Box className="flex-1">
                <Typography variant="subtitle2">
                  {t("members.firstname")}
                </Typography>
                <Typography variant="body1">{member.firstName}</Typography>
              </Box>
              <Box className="flex-1">
                <Typography variant="subtitle2">
                  {t("members.lastname")}
                </Typography>
                <Typography variant="body1">{member.lastName}</Typography>
              </Box>
            </Box>
            <Box className="flex gap-4 my-6">
              <Box className="flex-1">
                <Typography variant="subtitle2">
                  {t("members.email")}
                </Typography>
                <Typography variant="body1">{member.email}</Typography>
              </Box>
              <Box className="flex-1">
                <Typography variant="subtitle2">
                  {t("members.phone")}
                </Typography>
                <Typography variant="body1">{member.phone}</Typography>
              </Box>
            </Box>
            <Box className="flex gap-4 my-6">
              <Box className="flex-1">
                <Typography variant="subtitle2">
                  {t("members.membershipAppliedForLabel")}
                </Typography>
                <Typography variant="body1">{"—"}</Typography>
              </Box>
              <Box className="flex-1">
                <Typography variant="subtitle2">
                  {t("members.applicationDate")}
                </Typography>
                <Typography variant="body1">
                  {moment(new Date(Number(member.joinDate))).format(
                    "MMM DD, YYYY",
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className="flex mt-10 gap-4">
            <div></div>
            <Button
              color="error"
              variant="outlined"
              fullWidth
              onClick={() => handleReject()}
              disabled={isLoading}
            >
              {t("members.deny")}
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading}
              onClick={() => handleApprove()}
            >
              {t("members.approve")}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );

  return (
    <div>
      <Drawer open={isOpen} onClose={toggleDrawer(false)} anchor="right">
        {DrawerContent}
      </Drawer>
    </div>
  );
}
