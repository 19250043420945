import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";

import {
  Button,
  Chip,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TableCell,
  Typography,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { Clear, Troubleshoot } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Box from "@mui/material/Box";
import { GET_RESTAURANTS, INSPECT_RESTAURANTS } from "../../graphql";
import { useLazyQuery, useQuery } from "@apollo/client";
import { RestaurantInspection } from "../../types/PerformanceReport";
import { Restaurant } from "../../types";
import Spinner from "../../components/common/Spinner";

export default function Inspections() {
  const { setSnackBarMessageProps } = useContext(AppContext);

  const { t } = useTranslation();
  const [filters, setFilters] = useState({});
  const [startDate, setStartDate] = useState<moment.Moment | null>(null);
  const [endDate, setEndDate] = useState<moment.Moment | null>(null);
  const [visible, setVisible] = useState<RestaurantInspection[]>([]);

  const [restaurantNames, setRestaurantNames] = useState<
    (string | undefined)[]
  >([]);

  const [reportRestaurants, { loading: reportLoading }] = useLazyQuery<{
    inspectRestaurants: RestaurantInspection[];
  }>(INSPECT_RESTAURANTS, {
    fetchPolicy: "network-only",
  });

  const { data: restaurants, loading: restaurantsLoading } = useQuery<{
    restaurants: { items: Restaurant[]; count: number };
  }>(GET_RESTAURANTS, {
    variables: {
      city: "New York",
      offset: 0,
      limit: 100,
    },
    fetchPolicy: "network-only",
  });

  const loading = restaurantsLoading || reportLoading;

  const handleOnClickInspect = async () => {
    if (startDate && endDate && endDate.isBefore(startDate)) {
      if (setSnackBarMessageProps) {
        setSnackBarMessageProps({
          message: "End date can not be before start date",
          severity: "error",
        });
      }
      setVisible([]);
      return;
    }

    const result = await reportRestaurants({
      variables: {
        ...filters,
      },
    });
    setVisible(result.data?.inspectRestaurants ?? []);
  };

  const handleOnClickClear = async () => {
    setVisible([]);
  };

  const handleRestaurantSelectChange = (
    event: SelectChangeEvent<typeof restaurantNames>,
  ) => {
    const {
      target: { value },
    } = event;
    setRestaurantNames(typeof value === "string" ? value.split(",") : value);

    const uuids = (typeof value === "string" ? value.split(",") : value)
      .map(
        (name) =>
          restaurants?.restaurants?.items.find(
            (restaurant) => restaurant.name === name,
          )?.uuid,
      )
      .filter((uuid): uuid is string => !!uuid);
    setFilters((filters) => ({
      ...filters,
      restaurants: uuids,
    }));
  };

  const handleStartDateChange = (date: moment.Moment | null) => {
    if (date && endDate && date.isAfter(endDate)) {
      // If the start date is after the end date, show an error or adjust the end date
      if (setSnackBarMessageProps) {
        setSnackBarMessageProps({
          message: "Start date cannot be after the end date.",
          severity: "error",
        });
      }
      setStartDate(date);
      return;
    }

    setStartDate(date);

    // Optional: Update filters if needed
    setFilters((prevFilters) => ({
      ...prevFilters,
      from: date?.format("YYYY-MM-DD"),
    }));
  };

  const handleEndDateChange = (date: moment.Moment | null) => {
    if (date && startDate && date.isBefore(startDate)) {
      // If the end date is before the start date, show an error or adjust the start date
      if (setSnackBarMessageProps) {
        setSnackBarMessageProps({
          message: "End date cannot be before the start date.",
          severity: "error",
        });
      }
      setEndDate(date);
      return;
    }

    setEndDate(date);

    // Optional: Update filters if needed
    setFilters((prevFilters) => ({
      ...prevFilters,
      to: date?.format("YYYY-MM-DD"),
    }));
  };

  return (
    <div>
      <Container maxWidth="xl">
        <div className="flex justify-end my-8 space-x-4">
          <Button
            onClick={handleOnClickInspect}
            variant="contained"
            color="success"
          >
            <Troubleshoot /> {t("inspections.inspect")}
          </Button>
          <Button
            onClick={handleOnClickClear}
            variant="contained"
            color="primary"
          >
            <Clear /> {t("inspections.clear")}
          </Button>
        </div>
        <Grid container>
          <Grid sx={{ my: 1 }} xs={6} md={3}>
            <Typography variant="h6" noWrap>
              {t("inspections.title")}
            </Typography>
          </Grid>
          <Grid xs={12} md={9} sx={{ my: { xs: 2 } }}>
            <Box className="flex md:flex-row flex-col gap-4">
              <Box className="flex-1 pt-4">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    value={startDate}
                    onChange={handleStartDateChange}
                    format="dddd , MMMM  D, YYYY"
                    label={t("inspections.startDate")}
                  />
                </LocalizationProvider>
              </Box>
              <Box className="flex-1 pt-4">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    value={endDate}
                    onChange={handleEndDateChange}
                    label={t("inspections.endDate")}
                    format="dddd , MMMM  D, YYYY"
                  />
                </LocalizationProvider>
              </Box>
            </Box>

            <Box className="flex md:flex-row flex-col gap-4">
              <Box className="flex-1 pt-4">
                <FormControl fullWidth>
                  <InputLabel id="restaurant-multiple-chip-label">
                    {t("inspections.restaurants")}
                  </InputLabel>
                  <Select
                    labelId="restaurant-multiple-chip-label"
                    id="restaurant-multiple-chip"
                    multiple
                    value={restaurantNames}
                    onChange={handleRestaurantSelectChange}
                    input={
                      <OutlinedInput id="select-multiple-chip" label="Chip" />
                    }
                    renderValue={(names) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {names.map((name) => (
                          <Chip key={name} label={name} />
                        ))}
                      </Box>
                    )}
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        width: 250,
                      },
                    }}
                  >
                    {restaurants?.restaurants?.items.map((restaurant) => (
                      <MenuItem key={restaurant.name} value={restaurant.name}>
                        {restaurant.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Paper>
          {visible.length > 0 && (
            <TableContainer sx={{ maxHeight: "70vh" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="justify">
                      <Typography variant="h6">
                        {t("inspections.day")}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="h6">
                        {t("inspections.restaurantName")}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="h6">
                        {t("inspections.status")}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="h6">
                        {t("inspections.reason")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {visible?.map((entry) => {
                    return (
                      <TableRow
                        key={(entry?.day as string) + entry.restaurant.uuid}
                      >
                        <TableCell align="justify" width={140}>
                          <Typography variant="body2">
                            {moment(Number(entry.day)).format(
                              "dddd , MMMM  D, YYYY",
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" width={140}>
                          <Typography variant="body2">
                            {entry.restaurant.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" width={80}>
                          {((status) => {
                            switch (status) {
                              case "WARNING":
                                return <Chip label="WARNING" color="warning" />;
                              case "CLEAN":
                                return <Chip label="CLEAN" />;
                              case "FAULTY":
                                return <Chip label="FAULTY" color="error" />;
                            }
                          })(entry.status)}
                        </TableCell>
                        <TableCell align="center" width={400}>
                          <Typography variant="body2">
                            <>
                              {entry.reason.split("\n").map((line, index) => (
                                <span key={index}>
                                  {line}
                                  <br />
                                </span>
                              ))}
                            </>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {loading ? <Spinner /> : null}
        </Paper>
      </Container>
    </div>
  );
}
