export const getDateValue = (date: string | Number | Date) => {
  const fromNumber = new Date(Number(date));
  if (fromNumber.toString() !== "Invalid Date") {
    return fromNumber;
  }

  const fromString = new Date(String(date));
  if (fromString.toString() !== "Invalid Date") {
    return fromString;
  }

  return date as Date;
};
