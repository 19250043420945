import { useQuery } from "@apollo/client";
import moment from "moment";
import { West } from "@mui/icons-material";
import { MouseEvent, ReactNode, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Container } from "@mui/system";
import { useTranslation } from "react-i18next";

import MemberEdit from "./MemberEdit";
import { AppContext } from "../../context/AppContext";
import MemberProfile from "../../components/common/MemberProfile";
import PageSpinner from "../../components/common/PageSpinner";
import {
  Box,
  Button,
  Paper,
  styled,
  Tab,
  Tabs,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import MemberEditCredits from "./MemberEditCredits";
import MemberHistory, { HistoryType } from "./MemberHistory";
import {
  GET_ACTIVITY_FOR_MEMBER,
  GET_ACTIVITY_FOR_RESERVATION,
  GET_MEMBER,
  GET_TRANSACTIONS_FOR_MEMBER,
} from "../../graphql";
import { Member } from "@/app/types";
import NoData from "../../components/common/NoData";

const CustomTabs: any = styled((props) => <Tabs {...props} />)(() => ({
  "& .MuiTabs-scroller": {
    overflowX: "scroll !important",
  },
  minWidth: 0,
}));

function CustomTabPanel(props: {
  children: ReactNode;
  index: number;
  value: number;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

export default function MemberDetails() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { member, setMember } = useContext(AppContext);
  const {
    data: memberData,
    loading,
    error,
  } = useQuery<{ member: Member }>(GET_MEMBER, { variables: { uuid: id } });
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isCreditsOpen, setIsCreditsOpen] = useState(false);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (memberData?.member && !loading && !error) {
      setMember?.(memberData.member);
    }
  }, [memberData, setMember, error, loading]);

  const handleShowEdit = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    e.stopPropagation();
    setIsEditOpen(true);
  };

  const handleShowCredits = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    e.stopPropagation();
    setIsCreditsOpen(true);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <div>
      {member ? (
        <Container maxWidth="lg" className="mt-4">
          <Link to="/members" aria-label={t("members.title")}>
            <West />
          </Link>

          <Paper sx={{ padding: 4, mt: 2 }}>
            <Box className="flex flex-col md:flex-row gap-2 gap-y-2">
              <MemberProfile
                key={id}
                size="lg"
                member={member}
                subTitle={member.email}
              />

              <Box className="flex-1">
                <Box className="flex flex-col md:flex-row gap-2 justify-end">
                  <Button variant={"outlined"} onClick={handleShowEdit}>
                    {t("common.edit")}
                  </Button>
                  <Button variant={"outlined"} onClick={handleShowCredits}>
                    <Add />{" "}
                    {t(
                      member.balance
                        ? "members.manageCredits"
                        : "members.addCredits",
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box mt={4}>
              <CustomTabs
                value={tab}
                onChange={(_: Event, value: number) => setTab(value)}
              >
                <Tab label={t("members.tabOverview")} {...a11yProps(0)} />
                <Tab label={t("members.tabActivityLog")} {...a11yProps(1)} />
                <Tab label={t("members.creditActivityLog")} {...a11yProps(2)} />
                <Tab
                  label={t("members.transactionActivityLog")}
                  {...a11yProps(3)}
                />
              </CustomTabs>
              <Box>
                <CustomTabPanel value={tab} index={0}>
                  <Grid container spacing={2}>
                    <Grid xs={12} md={4}>
                      <Box>
                        <Typography variant="body2">
                          {t("members.availableCredits")}
                        </Typography>
                        <Typography variant="body1" mt={1}>
                          {member.balance}
                        </Typography>
                      </Box>
                      <Box className="mt-8">
                        <Typography variant="body2">
                          {t("members.birthday")}
                        </Typography>
                        <Typography variant="body1" mt={1}>
                          {moment(member.birthDate).format("MMM, DD YYYY")}
                        </Typography>
                      </Box>
                      <Box className="mt-8">
                        <Typography variant="body2">
                          {t("members.allergies")}
                        </Typography>
                        <Typography variant="body1" mt={1}>
                          {member.allergies}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={12} md={8}>
                      <Box>
                        <Typography variant="body2">
                          {t("members.bio")}
                        </Typography>
                        <Typography variant="body1" mt={1}>
                          {member.bio}
                        </Typography>
                      </Box>
                      <Box>
                        <Grid container>
                          <Grid xs={12} md={6}>
                            <Box mt={4}>
                              <Typography variant="body2">
                                {t("members.favoriteLiqour")}
                              </Typography>
                              <Typography variant="body1" mt={1}>
                                {member.preferences?.favouriteLiquor ?? "—"}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <Box mt={4}>
                              <Typography variant="body2">
                                {t("members.favoriteWine")}
                              </Typography>
                              <Typography variant="body1" mt={1}>
                                {member.preferences?.favouriteWine ?? "—"}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <Box mt={4}>
                              <Typography variant="body2">
                                {t("members.preference")}
                              </Typography>
                              <Typography variant="body1" mt={1}>
                                {member.preferences?.preference ?? "—"}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <Box mt={4}>
                              <Typography variant="body2">
                                {t("members.preferredDiningTime")}
                              </Typography>
                              <Typography variant="body1" mt={1}>
                                {member.preferences?.preferredDinningTime ??
                                  "—"}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box>
                    <Typography variant="h6" mt={4} mb={2}>
                      {t("members.contactInfo")}
                    </Typography>
                    <Grid container>
                      <Grid xs={12} md={4}>
                        <Box>
                          <Typography variant="body2">
                            {t("members.address")}
                          </Typography>
                          <Typography variant="body1" mt={1}>
                            {member.address.addressLine1}
                          </Typography>
                          <Typography variant="body1" mt={1}>
                            {member.address.addressLine2}
                          </Typography>
                          <Typography variant="body1" mt={1}>
                            {member.address.zipcode}{" "}
                            {[member.address.city, member.address.state]
                              .filter(Boolean)
                              .join(", ")}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={12} md={4}>
                        <Box>
                          <Typography variant="body2">
                            {t("members.email")}
                          </Typography>
                          <Typography variant="body1" mt={1}>
                            {member.email}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={12} md={4}>
                        <Box>
                          <Typography variant="body2">
                            {t("members.phone")}
                          </Typography>
                          <Typography variant="body1" mt={1}>
                            {member.phone}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={tab} index={1}>
                  <MemberHistory
                    uuid={member.uuid}
                    query={GET_ACTIVITY_FOR_RESERVATION}
                    type={HistoryType.RESERVATION}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={tab} index={2}>
                  <MemberHistory
                    uuid={member.uuid}
                    query={GET_ACTIVITY_FOR_MEMBER}
                    type={HistoryType.MEMBER}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={tab} index={3}>
                  <MemberHistory
                    uuid={member.uuid}
                    query={GET_TRANSACTIONS_FOR_MEMBER}
                    type={HistoryType.TRANSACTION}
                  />
                </CustomTabPanel>
              </Box>
            </Box>
          </Paper>
          {isEditOpen && member && (
            <MemberEdit
              isOpen={isEditOpen}
              member={member}
              setIsOpen={setIsEditOpen}
            />
          )}
          {isCreditsOpen && member && (
            <MemberEditCredits
              isOpen={isCreditsOpen}
              member={member}
              setIsOpen={setIsCreditsOpen}
            />
          )}
        </Container>
      ) : loading ? (
        <PageSpinner />
      ) : (
        <NoData title={t("members.memberNotFound")} />
      )}
    </div>
  );
}
