import { DomainEnv, getEnv } from "../config";

export const getBackendUrl = () => {
  const subdomain = getEnv();
  switch (subdomain) {
    case DomainEnv.dev:
    case DomainEnv.test:
      return `https://backend.${subdomain}.accessonboard.com`;
    case DomainEnv.prod:
      return "https://api.accessmembers.com";
    case DomainEnv.local:
    default:
      return "http://localhost:8000";
  }
};

export const getAuthURL = () => {
  const subdomain = getEnv();
  switch (subdomain) {
    case DomainEnv.dev:
    case DomainEnv.test:
      return `https://keycloak.${subdomain}.accessonboard.com`;
    case DomainEnv.prod:
      return "https://identity.accessmembers.com";
    case DomainEnv.local:
    default:
      return "http://localhost:8080";
  }
};
