import { t } from "i18next";
import { MouseEvent, useContext, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../../../assets/images/logo-small-dark.svg";
import { AppContext } from "../../context/AppContext";
import { logout } from "../../../keycloak";
import { useNavigate } from "react-router-dom";

const pages = [
  { path: "/restaurants", label: t("restaurants.title") },
  { path: "/members", label: t("members.title") },
  { path: "/inspection", label: t("inspections.title") },
];

const settings = [{ key: "logout", title: t("auth.logout") }];

export default function NavigationBar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const { currentUser } = useContext(AppContext);
  const { pathname } = window.location;
  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenuNav = (
    e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>,
    path: string,
  ) => {
    e.preventDefault();
    navigate(path);
  };

  const handleCloseUserMenu = (setting: string) => {
    setAnchorElUser(null);
    switch (setting) {
      case "logout":
        logout();
        break;
    }
  };

  return (
    <AppBar position="static" color="primary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <a
            href="/"
            className="flex-1 md:flex-none"
            onClick={(e) => handleMenuNav(e, "/")}
          >
            <img src={Logo} alt="logo" width={120} height={40} />
          </a>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label={t("navbar.menu")}
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.path} onClick={handleCloseNavMenu}>
                  <Typography
                    textAlign="center"
                    fontWeight={pathname.startsWith(page.path) ? 700 : 300}
                    component="a"
                    onClick={(e) => handleMenuNav(e, page.path)}
                    href={page.path}
                  >
                    {page.label}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex", gap: 10, marginLeft: 20 },
            }}
          >
            {pages.map((page) => (
              <Typography
                variant="button"
                key={page.path}
                component="a"
                href={page.path}
                onClick={(e) => handleMenuNav(e, page.path)}
                fontWeight={pathname.startsWith(page.path) ? 700 : 300}
              >
                {page.label}
              </Typography>
            ))}
          </Box>

          {currentUser && (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title={currentUser.name}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting.key}
                    onClick={() => handleCloseUserMenu(setting.key)}
                  >
                    <Typography textAlign="center">{setting.title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
