import {
  ChangeEvent,
  MouseEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { t } from "i18next";
import moment from "moment";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import FilterListIcon from "@mui/icons-material/FilterList";

import TableRow from "@mui/material/TableRow";
import {
  Backdrop,
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import SearchInput from "../../components/common/SearchInput";
import MemberProfile from "../../components/common/MemberProfile";
import ReservationDetails from "./ReservationDetails";
import { Reservation } from "@/app/types/Reservation";
import NoData from "../../components/common/NoData";
import DateRangePicker from "./DateRangePicker";
import {
  GET_RESERVATIONS,
  UPDATE_RESERVATION_CREDIT,
  UPDATE_RESERVATION_STATUS,
} from "../../graphql";
import { useMutation, useQuery } from "@apollo/client";
import { AppContext } from "../../context/AppContext";
import { ReservationStatus, Severity } from "../../constants";
import { getDateValue } from "../../utils/getDateValue.util";
import { useParams } from "react-router-dom";
import Spinner from "../../components/common/Spinner";
import AccountHeader from "../account/AccountHeader";
import DropMenu from "../../components/menu/DropMenu";

// currently the BE does not support pagination
const ENABLE_PAGINATION = true;

interface Column {
  id:
    | "keys"
    | "status"
    | "member"
    | "date"
    | "time"
    | "table"
    | "guests"
    | "actions";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: Date | number | string) => string;
}

const timelineFiltersEnabled = false;
const columns: readonly Column[] = [
  {
    id: "date",
    label: "Date",
    minWidth: 100,
  },
  {
    id: "time",
    label: t("reservations.time"),
    minWidth: 100,
  },
  {
    id: "table",
    label: t("reservations.table"),
    minWidth: 70,
  },
  {
    id: "guests",
    label: t("reservations.guests"),
    minWidth: 50,
  },
  { id: "status", label: t("reservations.status"), minWidth: 100 },
  { id: "keys", label: t("reservations.keys"), minWidth: 50 },
  { id: "member", label: t("reservations.member"), minWidth: 170 },
  {
    id: "actions",
    label: "",
    minWidth: 200,
    align: "right",
  },
];

enum DateFilter {
  NEXT_7_DAYS = "next-7-days",
  LAST_30_DAYS = "last-30-days",
  THIS_YEAR = "this-year",
  LAST_YEAR = "last-year",
}

const dateFilters = Object.values(DateFilter);

enum TimelineFilter {
  ALL = "all",
  UPCOMING = "upcoming",
  PREVIOUS = "previous",
}

enum Order {
  ASC = "asc",
  DESC = "desc",
}

enum RowFields {
  STATUS = "status",
  MEMBER = "member",
  DATE = "date",
  TIME = "time",
  TABLE = "table",
  GUESTS = "guests",
  KEYS = "keys",
}

const timeLineFilters = Object.values(TimelineFilter);

function descendingComparator(
  a: Reservation,
  b: Reservation,
  orderBy: RowFields,
) {
  let comparatorA: string | number | Date = a.date.start;
  let comparatorB: string | number | Date = b.date.start;
  switch (orderBy) {
    case RowFields.MEMBER:
      comparatorA = a.member?.preferredName;
      comparatorB = b.member?.preferredName;
      break;
    case RowFields.DATE:
    case RowFields.TIME:
      comparatorA = a.date.start;
      comparatorB = b.date.start;
      break;
    case RowFields.TABLE:
      comparatorA = a.table.label;
      comparatorB = b.table.label;
      break;
    case RowFields.GUESTS:
      comparatorA = a.table.maxPartySize;
      comparatorB = b.table.maxPartySize;
      break;
  }

  if (comparatorB < comparatorA) {
    return -1;
  }
  if (comparatorB > comparatorA) {
    return 1;
  }

  return 0;
}

function getComparator(order: Order, orderBy: RowFields) {
  return order === Order.DESC
    ? (a: Reservation, b: Reservation) => descendingComparator(a, b, orderBy)
    : (a: Reservation, b: Reservation) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: Reservation[], comparator: Function) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }

    switch (typeof a[1]) {
      case "number":
        return a[1] - Number(b[1]);
      case "string":
        return String(a[1]).localeCompare(b[1].toString());
      default:
        return a[1] < b[1] ? -1 : 1;
    }
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Reservations() {
  const [page, setPage] = useState(0);
  const { id: uuid } = useParams<{ id: string }>();
  const { restaurant, setSnackBarMessageProps } = useContext(AppContext);
  const [isDateRangeModalOpen, setIsDateRangeModalOpen] = useState(false);
  const [keysDialogOpen, setKeysDialogOpen] = useState(false);
  const [clickedUUID, setClickedUUID] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [timelineFilter, setTimelineFilter] = useState(TimelineFilter.ALL);
  const [order, setOrder] = useState(Order.DESC);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [orderBy, setOrderBy] = useState<RowFields | null>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);
  const statusMenuOpen = Boolean(statusAnchorEl);
  const [dateRange, setDateRange] = useState<moment.Moment[]>([]);
  const [searchText, setSearchText] = useState("");
  const [reservations, setReservations] = useState<Reservation[] | undefined>();
  const [selectedReservation, setSelectedReservation] =
    useState<Reservation | null>(null);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [selectedDateFilter, setSelectedDateFilter] =
    useState<DateFilter | null>(null);
  const dateFilterOpen = Boolean(anchorEl);
  const {
    data: reservationsData,
    loading: reservationsLoading,
    refetch,
  } = useQuery<{ reservations: { items: Reservation[]; count: number } }>(
    GET_RESERVATIONS,
    {
      variables: { uuid, limit: rowsPerPage },
    },
  );
  const [updateReservationCredit, updateReservationCreditResponse] =
    useMutation(UPDATE_RESERVATION_CREDIT);
  const [updateReservationStatus, updateReservationStatusResponse] =
    useMutation(UPDATE_RESERVATION_STATUS);

  const loading =
    reservationsLoading ||
    updateReservationCreditResponse.loading ||
    updateReservationStatusResponse.loading;

  useEffect(() => {
    setIsLoading(loading);
    if (reservationsData?.reservations && !loading) {
      setReservations!(reservationsData.reservations.items);
    }
  }, [reservationsData, setReservations, reservations, loading]);
  const handleStatusChangeClick = (row: any, event: any) => {
    setClickedUUID(row.uuid);
    setStatusAnchorEl(event.currentTarget);
  };
  const handleStatusChangeMenuClose = () => {
    setStatusAnchorEl(null);
  };

  const handleKeysChangeClick = (row: any, event: any) => {
    setClickedUUID(row.uuid);
    setKeysDialogOpen(true);
  };
  const handleKeysChangeDialogClose = () => {
    setKeysDialogOpen(false);
  };

  const handleShowDateFilter = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDateFilter = () => {
    setAnchorEl(null);
  };

  const handleDateFilterChanged = async (
    newRange: moment.Moment[],
    newFilter: DateFilter | null,
  ) => {
    try {
      const filters = getDateFilterResultQuery(newRange, newFilter);
      setIsLoading(true);
      setFilters(filters);
      await refetch({
        uuid: restaurant?.uuid,
        ...filters,
      });
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("common.error", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });

      return error;
    } finally {
      setIsLoading(false);
    }
  };
  const handleSelectDateFilter = async (filter: DateFilter | null) => {
    setSelectedDateFilter(filter);
    setDateRange([]);
    handleCloseDateFilter();
    await handleDateFilterChanged([], filter);
  };

  const handleShowDateRangePicker = () => {
    setIsDateRangeModalOpen(true);
    handleCloseDateFilter();
  };

  const handleSetDateRange = (dateRange: moment.Moment[]) => {
    setSelectedDateFilter(null);
    setDateRange(dateRange);
    handleDateFilterChanged(dateRange, null);
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setIsLoading(true);
    try {
      await refetch({
        ...filters,
        uuid,
        limit: rowsPerPage,
        offset: newPage * rowsPerPage,
      });
      setPage(newPage);
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("common.error", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });
      return error;
    }

    setIsLoading(false);
  };

  const handleChangeRowsPerPage = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setIsLoading(true);
    try {
      await refetch({
        ...filters,
        uuid,
        limit: newRowsPerPage,
        offset: 0,
      });
      setPage(0);
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("common.error", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });
      return error;
    }
  };

  const handleReservationUpdate = async () => {
    setIsLoading(true);
    try {
      await refetch({
        ...filters,
        uuid,
      });
      setPage(0);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("common.error", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });
      return error;
    }
  };

  const handleShowDetail = (row: Reservation) => {
    setSelectedReservation(row);
    setIsDetailOpen(true);
  };

  const createSortHandler =
    (property: RowFields) => (event: MouseEvent<HTMLAnchorElement>) => {
      const isAsc = orderBy === property && order === Order.ASC;
      setOrder(isAsc ? Order.DESC : Order.ASC);
      setOrderBy(property);
    };

  const getDateFilterResultQuery = (
    newRange: moment.Moment[],
    newFilter: DateFilter | null,
  ) => {
    const [from, to] = newRange;
    if (from && to) {
      return {
        from: from.format("YYYY-MM-DD"),
        to: to.format("YYYY-MM-DD"),
      };
    }

    switch (newFilter) {
      case DateFilter.NEXT_7_DAYS:
        return {
          from: moment().startOf("day").format("YYYY-MM-DD"),
          to: moment().add(7, "days").endOf("day").format("YYYY-MM-DD"),
        };

      case DateFilter.LAST_30_DAYS:
        return {
          from: moment()
            .subtract(30, "days")
            .startOf("day")
            .format("YYYY-MM-DD"),
          to: moment().endOf("day").format("YYYY-MM-DD"),
        };

      case DateFilter.THIS_YEAR:
        return {
          from: moment().startOf("year").format("YYYY-MM-DD"),
          to: moment().endOf("year").format("YYYY-MM-DD"),
        };

      case DateFilter.LAST_YEAR:
        return {
          from: moment()
            .subtract(1, "year")
            .startOf("year")
            .format("YYYY-MM-DD"),
          to: moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD"),
        };

      default:
        return {};
    }
  };

  const visibleRows = (
    useMemo(
      () => stableSort(reservations ?? [], getComparator(order, orderBy!)),
      [order, orderBy, reservations],
    ) as Reservation[]
  ).filter((row) => {
    if (!searchText) {
      return true;
    }

    return (
      row.member?.preferredName
        ?.toLowerCase()
        ?.includes?.(searchText.toLowerCase()) ||
      row.member?.email?.toLowerCase?.().includes?.(searchText.toLowerCase())
    );
  });

  const tableContent = (
    <Paper>
      <TableContainer sx={{ maxHeight: "60vh" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sortDirection={orderBy === column.id ? order : false}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.id === "date" ? (
                    <Typography variant="body2">
                      {column.label}
                      <IconButton
                        id="basic-button"
                        aria-controls="basic-menu"
                        aria-haspopup="true"
                        aria-expanded={dateFilterOpen ? "true" : undefined}
                        onClick={handleShowDateFilter}
                      >
                        <FilterListIcon />
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        sx={{ width: 500 }}
                        anchorEl={anchorEl}
                        open={dateFilterOpen}
                        onClose={handleCloseDateFilter}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {dateFilters.map((filter) => (
                          <MenuItem
                            selected={selectedDateFilter === filter}
                            key={filter}
                            onClick={() => handleSelectDateFilter(filter)}
                          >
                            <Typography variant="body2">
                              {t(`reservations.dateFilter-${filter}`)}
                              {filter === DateFilter.THIS_YEAR &&
                                moment().format(" (YYYY)")}
                              {filter === DateFilter.LAST_YEAR &&
                                moment().subtract(1, "year").format(" (YYYY)")}
                            </Typography>
                          </MenuItem>
                        ))}
                        <Divider />
                        <MenuItem onClick={() => handleShowDateRangePicker()}>
                          <Typography variant="body2">
                            {t("reservations.selectDateRange")}
                          </Typography>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                          disabled={
                            !selectedDateFilter && dateRange.length === 0
                          }
                          onClick={() => handleSelectDateFilter(null)}
                        >
                          <Typography variant="body2">
                            {t(`common.clearAll`)}
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </Typography>
                  ) : (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : Order.ASC}
                      onClick={createSortHandler(column.id as RowFields)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === Order.DESC
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {!isLoading &&
              visibleRows.map((row, i) => {
                return (
                  <TableRow hover role="button" tabIndex={-1} key={i}>
                    {columns.map((column) => {
                      switch (column.id) {
                        case RowFields.STATUS:
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {row.status ? row.status.toString() : "—"}
                            </TableCell>
                          );
                        case RowFields.KEYS:
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {row.credit?.amount
                                ? row.credit?.amount.toString()
                                : "—"}
                            </TableCell>
                          );
                        case RowFields.MEMBER:
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {row.member ? (
                                <MemberProfile member={row.member} />
                              ) : row.status === ReservationStatus.RESERVED ? (
                                "Guest"
                              ) : (
                                "—"
                              )}
                            </TableCell>
                          );
                        case RowFields.TIME:
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {moment(getDateValue(row.date.start))
                                .format("h:mm a")
                                .toString()}
                            </TableCell>
                          );
                        case RowFields.DATE:
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <Tooltip
                                placement="top-start"
                                title={moment(
                                  getDateValue(row.date.start),
                                ).format("MMM DD, YYYY")}
                              >
                                <Typography variant="body2" noWrap>
                                  {moment(getDateValue(row.date.start)).format(
                                    "MMMM DD, YYYY",
                                  )}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                          );
                        case RowFields.GUESTS:
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {row.table.maxPartySize}
                            </TableCell>
                          );
                        case RowFields.TABLE:
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {row.table.location} &rarr; {row.table.label}
                            </TableCell>
                          );
                        case "actions":
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <Stack spacing={1} direction="row">
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  onClick={() => handleShowDetail(row)}
                                >
                                  {t("common.view")}
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  onClick={(event) =>
                                    handleStatusChangeClick(row, event)
                                  }
                                >
                                  {t("common.change_status")}
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  onClick={(event) =>
                                    handleKeysChangeClick(row, event)
                                  }
                                >
                                  {t("common.change_keys")}
                                </Button>
                              </Stack>

                              <DropMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                  "aria-labelledby": "demo-customized-button",
                                }}
                                anchorEl={statusAnchorEl}
                                onClose={handleStatusChangeMenuClose}
                                open={statusMenuOpen}
                              >
                                <MenuItem
                                  onClick={async () => {
                                    try {
                                      await updateReservationStatus({
                                        variables: {
                                          uuid: clickedUUID,
                                          status: ReservationStatus.CANCELED,
                                        },
                                      });
                                      await handleReservationUpdate();

                                      setSnackBarMessageProps?.({
                                        message: t(
                                          "reservations.update_status_success",
                                        ),
                                        severity: Severity.Success,
                                      });
                                    } catch (error) {
                                      setSnackBarMessageProps?.({
                                        message: t(
                                          "reservations.update_status_error",
                                          {
                                            error: (error as Error).message,
                                          },
                                        ),
                                        severity: Severity.Error,
                                      });
                                    }
                                    handleStatusChangeMenuClose();
                                  }}
                                  disableRipple
                                >
                                  Canceled
                                </MenuItem>
                                <MenuItem
                                  onClick={async () => {
                                    try {
                                      await updateReservationStatus({
                                        variables: {
                                          uuid: clickedUUID,
                                          status: ReservationStatus.AVAILABLE,
                                        },
                                      });
                                      await handleReservationUpdate();

                                      setSnackBarMessageProps?.({
                                        message: t(
                                          "reservations.update_status_success",
                                        ),
                                        severity: Severity.Success,
                                      });
                                    } catch (error) {
                                      setSnackBarMessageProps?.({
                                        message: t(
                                          "reservations.update_status_error",
                                          {
                                            error: (error as Error).message,
                                          },
                                        ),
                                        severity: Severity.Error,
                                      });
                                    }
                                    handleStatusChangeMenuClose();
                                  }}
                                  disableRipple
                                >
                                  Available
                                </MenuItem>
                                <MenuItem
                                  onClick={async () => {
                                    try {
                                      await updateReservationStatus({
                                        variables: {
                                          uuid: clickedUUID,
                                          status: ReservationStatus.LISTED,
                                        },
                                      });
                                      await handleReservationUpdate();

                                      setSnackBarMessageProps?.({
                                        message: t(
                                          "reservations.update_status_success",
                                        ),
                                        severity: Severity.Success,
                                      });
                                    } catch (error) {
                                      setSnackBarMessageProps?.({
                                        message: t(
                                          "reservations.update_status_error",
                                          {
                                            error: (error as Error).message,
                                          },
                                        ),
                                        severity: Severity.Error,
                                      });
                                    }
                                    handleStatusChangeMenuClose();
                                  }}
                                  disableRipple
                                >
                                  Listed
                                </MenuItem>
                                <MenuItem
                                  onClick={async () => {
                                    try {
                                      await updateReservationStatus({
                                        variables: {
                                          uuid: clickedUUID,
                                          status: ReservationStatus.RELEASED,
                                        },
                                      });
                                      await handleReservationUpdate();

                                      setSnackBarMessageProps?.({
                                        message: t(
                                          "reservations.update_status_success",
                                        ),
                                        severity: Severity.Success,
                                      });
                                    } catch (error) {
                                      setSnackBarMessageProps?.({
                                        message: t(
                                          "reservations.update_status_error",
                                          {
                                            error: (error as Error).message,
                                          },
                                        ),
                                        severity: Severity.Error,
                                      });
                                    }
                                    handleStatusChangeMenuClose();
                                  }}
                                  disableRipple
                                >
                                  Released
                                </MenuItem>
                              </DropMenu>

                              <Dialog
                                onClose={handleKeysChangeDialogClose}
                                open={keysDialogOpen}
                                fullScreen={false}
                                slots={{ backdrop: Backdrop }}
                                slotProps={{
                                  backdrop: {
                                    sx: {
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.01)", // Semi-transparent
                                      // white
                                      // background
                                    },
                                  },
                                }}
                                PaperProps={{
                                  component: "form",
                                  onSubmit: async (
                                    event: React.FormEvent<HTMLFormElement>,
                                  ) => {
                                    event.preventDefault();
                                    const formData = new FormData(
                                      event.currentTarget,
                                    );
                                    const formJson = Object.fromEntries(
                                      (formData as any).entries(),
                                    );
                                    const keys = Number(formJson.keys);
                                    try {
                                      await updateReservationCredit({
                                        variables: {
                                          uuid: clickedUUID,
                                          credit: keys,
                                        },
                                      });

                                      await handleReservationUpdate();
                                      setSnackBarMessageProps?.({
                                        message: t(
                                          "reservations.update_keys_success",
                                        ),
                                        severity: Severity.Success,
                                      });
                                    } catch (error) {
                                      setSnackBarMessageProps?.({
                                        message: t(
                                          "reservations.update_keys_error",
                                          {
                                            error: (error as Error).message,
                                          },
                                        ),
                                        severity: Severity.Error,
                                      });
                                    }
                                    handleKeysChangeDialogClose();
                                  },
                                  style: {
                                    borderRadius: "16px", // Rounded corners
                                    padding: "20px", // Inner padding
                                    boxShadow:
                                      "0 4px 20px rgba(255, 255, 255, 0.2)", // Add a
                                    // shadow
                                    // effect
                                  },
                                }}
                              >
                                <DialogTitle>Reservation Keys</DialogTitle>
                                <DialogContent>
                                  <DialogContentText>
                                    Assign the keys for reservation
                                  </DialogContentText>
                                  <TextField
                                    autoFocus
                                    required
                                    margin="dense"
                                    id="keys"
                                    name="keys"
                                    label="Keys"
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                  />
                                </DialogContent>
                                <DialogActions>
                                  <Button type="submit" autoFocus>
                                    Agree
                                  </Button>
                                  <Button onClick={handleKeysChangeDialogClose}>
                                    Cancel
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </TableCell>
                          );
                        default:
                          return (
                            <TableCell key={column.id} align={column.align}>
                              --
                            </TableCell>
                          );
                      }
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading ? <Spinner /> : null}
      {visibleRows.length === 0
        ? !isLoading && (
            <NoData
              topMargin="0"
              title={t("reservations.noDataTitle")}
              message={t("reservations.noDataText")}
            />
          )
        : ENABLE_PAGINATION && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              disabled={isLoading}
              count={reservationsData?.reservations.count ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              translate="yes"
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
    </Paper>
  );

  return (
    <div>
      <AccountHeader />
      {selectedReservation && (
        <ReservationDetails
          isOpen={isDetailOpen}
          reservation={selectedReservation}
          setIsOpen={setIsDetailOpen}
          refresh={handleReservationUpdate}
        />
      )}
      <Container maxWidth="xl">
        <Grid xs={12} sm={7}>
          <div className="flex gap-2">
            <div className="flex-1">
              <Typography variant="h6">{restaurant?.name}</Typography>
              <Typography variant="body1">
                {[restaurant?.city?.name, restaurant?.city?.location]
                  .filter(Boolean)
                  .join(", ")}{" "}
                {restaurant?.city?.country &&
                  t(`countries.${restaurant?.city.country}`)}
              </Typography>
            </div>
            <div className="justify-end md:mr-5">
              <Chip
                label={t(`restaurants.listed-${restaurant?.listed}`)}
                color={restaurant?.listed ? "success" : "error"}
              />
            </div>
          </div>
        </Grid>
        <Grid container className="my-8">
          <Grid item xs={12} md={9} sx={{ my: 1 }}>
            <Typography variant="h6" noWrap>
              {t("reservations.title")}
            </Typography>
            {timelineFiltersEnabled && (
              <div className="mt-4">
                {timeLineFilters.map((filter) => (
                  <Chip
                    key={filter}
                    variant={timelineFilter === filter ? "outlined" : "filled"}
                    onClick={() => setTimelineFilter(filter)}
                    sx={{ marginRight: 1 }}
                    label={t(`reservations.timeLine-${filter}`)}
                  />
                ))}
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={3} sx={{ my: { xs: 2 } }}>
            <SearchInput
              placeholder={t("common.search")}
              change={setSearchText}
            ></SearchInput>
          </Grid>
        </Grid>
        {tableContent}
        <DateRangePicker
          save={handleSetDateRange}
          isOpen={isDateRangeModalOpen}
          handleClose={() => setIsDateRangeModalOpen(false)}
        />
      </Container>
    </div>
  );
}
