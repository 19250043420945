import {
  Backdrop,
  Box,
  Button,
  Divider,
  Fade,
  Modal,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { modalStyle, Severity } from "../../constants";

export default function ConfirmModal({
  handleConfirm,
  title,
  actionTitle,
  actionColor = Severity.Error,
  message,
  inputIsOpen,
  inputHandleClose,
}: {
  handleConfirm: () => void;
  title: string;
  actionTitle: string;
  actionColor?: Severity;
  message: string;
  inputIsOpen: boolean;
  inputHandleClose: () => void;
}) {
  const [isOpen, setIsOpen] = useState(inputIsOpen);
  const { t } = useTranslation();
  const handleClose = () => {
    setIsOpen(false);
    inputHandleClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isOpen}>
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {message}
          </Typography>
          <Divider sx={{ mt: 2 }} />
          <div className="flex gap-2 justify-end">
            <Button onClick={handleClose} sx={{ mt: 2 }} variant="contained">
              {t("common.cancel")}
            </Button>
            <Button onClick={handleConfirm} sx={{ mt: 2 }} color={actionColor}>
              {actionTitle}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
