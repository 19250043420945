import { useTranslation } from "react-i18next";
import { ChangeEvent, useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";

import AccountHeader from "./AccountHeader";
import {
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  Typography,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import { useQuery } from "@apollo/client";
import { REPORT_RESTAURANT_PERFORMANCE } from "../../graphql";
import { PerformanceReport } from "../../types/PerformanceReport";
import moment from "moment";
import TablePagination from "@mui/material/TablePagination";
import { Severity } from "../../constants";
import Spinner from "../../components/common/Spinner";
import NoData from "../../components/common/NoData";
import FilterListIcon from "@mui/icons-material/FilterList";
import DateRangePicker from "../../pages/reservations/DateRangePicker";

enum DateFilter {
  NEXT_10_DAYS = "next-10-days",
  NEXT_15_DAYS = "next-15-days",
  NEXT_30_DAYS = "next-30-days",
  LAST_30_DAYS = "last-30-days",
  LAST_15_DAYS = "last-15-days",
  LAST_10_DAYS = "last-10-days",
  NEXT_6_MONTHS = "next-6-months",
  NEXT_3_MONTHS = "next-3-months",
  LAST_6_MONTHS = "last-6-months",
  LAST_3_MONTHS = "last-3-months",
  THIS_YEAR = "this-year",
  LAST_YEAR = "last-year",
}

export default function Performance() {
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const dateFilterOpen = Boolean(anchorEl);
  const [selectedDateFilter, setSelectedDateFilter] =
    useState<DateFilter | null>(null);
  const [isDateRangeModalOpen, setIsDateRangeModalOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [dateRange, setDateRange] = useState<moment.Moment[]>([]);

  const { resyTableTypes, restaurant, setSnackBarMessageProps } =
    useContext(AppContext);
  const {
    data: report,
    loading,
    refetch,
  } = useQuery<{
    reportRestaurantPerformance: {
      count: number;
      report: PerformanceReport[];
    };
  }>(REPORT_RESTAURANT_PERFORMANCE, {
    variables: {
      uuid: restaurant?.uuid,
      ...filters,
    },
    fetchPolicy: "network-only",
  });

  const getDateFilterResultQuery = (
    newRange: moment.Moment[],
    newFilter: DateFilter | null,
  ) => {
    const [from, to] = newRange;
    if (from && to) {
      return {
        from: from.format("YYYY-MM-DD"),
        to: to.format("YYYY-MM-DD"),
      };
    }

    switch (newFilter) {
      case DateFilter.NEXT_10_DAYS:
        return {
          from: moment().startOf("day").format("YYYY-MM-DD"),
          to: moment().add(10, "days").endOf("day").format("YYYY-MM-DD"),
        };
      case DateFilter.NEXT_15_DAYS:
        return {
          from: moment().startOf("day").format("YYYY-MM-DD"),
          to: moment().add(15, "days").endOf("day").format("YYYY-MM-DD"),
        };
      case DateFilter.NEXT_30_DAYS:
        return {
          from: moment().startOf("day").format("YYYY-MM-DD"),
          to: moment().add(30, "days").endOf("day").format("YYYY-MM-DD"),
        };

      case DateFilter.LAST_30_DAYS:
        return {
          from: moment()
            .subtract(30, "days")
            .startOf("day")
            .format("YYYY-MM-DD"),
          to: moment().endOf("day").format("YYYY-MM-DD"),
        };
      case DateFilter.LAST_15_DAYS:
        return {
          from: moment()
            .subtract(15, "days")
            .startOf("day")
            .format("YYYY-MM-DD"),
          to: moment().endOf("day").format("YYYY-MM-DD"),
        };
      case DateFilter.LAST_10_DAYS:
        return {
          from: moment()
            .subtract(10, "days")
            .startOf("day")
            .format("YYYY-MM-DD"),
          to: moment().endOf("day").format("YYYY-MM-DD"),
        };

      case DateFilter.NEXT_3_MONTHS:
        return {
          from: moment().startOf("day").format("YYYY-MM-DD"),
          to: moment().add(3, "months").endOf("day").format("YYYY-MM-DD"),
        };
      case DateFilter.NEXT_6_MONTHS:
        return {
          from: moment().startOf("day").format("YYYY-MM-DD"),
          to: moment().add(6, "months").endOf("day").format("YYYY-MM-DD"),
        };

      case DateFilter.LAST_3_MONTHS:
        return {
          from: moment()
            .subtract(3, "months")
            .startOf("day")
            .format("YYYY-MM-DD"),
          to: moment().endOf("day").format("YYYY-MM-DD"),
        };
      case DateFilter.LAST_6_MONTHS:
        return {
          from: moment()
            .subtract(6, "months")
            .startOf("day")
            .format("YYYY-MM-DD"),
          to: moment().endOf("day").format("YYYY-MM-DD"),
        };

      case DateFilter.THIS_YEAR:
        return {
          from: moment().startOf("year").format("YYYY-MM-DD"),
          to: moment().endOf("year").format("YYYY-MM-DD"),
        };

      case DateFilter.LAST_YEAR:
        return {
          from: moment()
            .subtract(1, "year")
            .startOf("year")
            .format("YYYY-MM-DD"),
          to: moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD"),
        };

      default:
        return {};
    }
  };

  const handleShowDateFilter = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDateFilter = () => {
    setAnchorEl(null);
  };

  const handleDateFilterChanged = async (
    newRange: moment.Moment[],
    newFilter: DateFilter | null,
  ) => {
    try {
      const filters = getDateFilterResultQuery(newRange, newFilter);
      setFilters(filters);
      await refetch({
        uuid: restaurant?.uuid,
        ...filters,
      });
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("common.error", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });

      return error;
    }
  };

  const handleSelectDateFilter = async (filter: DateFilter | null) => {
    setSelectedDateFilter(filter);
    setDateRange([]);
    handleCloseDateFilter();
    await handleDateFilterChanged([], filter);
  };

  const handleShowDateRangePicker = () => {
    setIsDateRangeModalOpen(true);
    handleCloseDateFilter();
  };

  const handleSetDateRange = (dateRange: moment.Moment[]) => {
    setSelectedDateFilter(null);
    setDateRange(dateRange);
    handleDateFilterChanged(dateRange, null);
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    try {
      await refetch({
        uuid: restaurant?.uuid,
        limit: rowsPerPage,
        offset: newPage * rowsPerPage,
      });
      setPage(newPage);
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("common.error", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });
      return error;
    }
  };

  const handleChangeRowsPerPage = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    try {
      await refetch({
        uuid: restaurant?.uuid,
        limit: newRowsPerPage,
        offset: 0,
      });
      setPage(0);
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("common.error", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });
      return error;
    }
  };

  if (!restaurant) {
    return null;
  }

  return (
    <div>
      <AccountHeader />
      <Container maxWidth="xl">
        <Grid item xs={12} sm={7}>
          <div className="flex gap-2">
            <div className="flex-1">
              <Typography variant="h6">{restaurant.name}</Typography>
              <Typography variant="body1">
                {[restaurant.city?.name, restaurant.city?.location]
                  .filter(Boolean)
                  .join(", ")}{" "}
                {restaurant.city?.country &&
                  t(`countries.${restaurant.city.country}`)}
              </Typography>
            </div>
            <div className="justify-end md:mr-5">
              <Chip
                label={t(`restaurants.listed-${restaurant.listed}`)}
                color={restaurant.listed ? "success" : "error"}
              />
            </div>
          </div>
        </Grid>
        <Grid container className="my-8">
          <Grid item xs={12} md={9} sx={{ my: 1 }}>
            <Typography variant="h6" noWrap>
              {t("restaurantPerformance.performance")}
            </Typography>
          </Grid>
        </Grid>

        <Paper>
          <TableContainer sx={{ maxHeight: "60vh" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="justify">
                    <Typography variant="body2">
                      {t("restaurantPerformance.date")}
                      <IconButton
                        id="basic-button"
                        aria-controls="basic-menu"
                        aria-haspopup="true"
                        aria-expanded={dateFilterOpen ? "true" : undefined}
                        onClick={handleShowDateFilter}
                      >
                        <FilterListIcon />
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        sx={{ width: 500 }}
                        anchorEl={anchorEl}
                        open={dateFilterOpen}
                        onClose={handleCloseDateFilter}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {Object.values(DateFilter).map((filter) => (
                          <MenuItem
                            selected={selectedDateFilter === filter}
                            key={filter}
                            onClick={() => handleSelectDateFilter(filter)}
                          >
                            <Typography variant="body2">
                              {t(`reservations.dateFilter-${filter}`)}
                              {filter === DateFilter.THIS_YEAR &&
                                moment().format(" (YYYY)")}
                              {filter === DateFilter.LAST_YEAR &&
                                moment().subtract(1, "year").format(" (YYYY)")}
                            </Typography>
                          </MenuItem>
                        ))}
                        <Divider />
                        <MenuItem onClick={() => handleShowDateRangePicker()}>
                          <Typography variant="body2">
                            {t("reservations.selectDateRange")}
                          </Typography>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                          disabled={
                            !selectedDateFilter && dateRange.length === 0
                          }
                          onClick={() => handleSelectDateFilter(null)}
                        >
                          <Typography variant="body2">
                            {t(`common.clearAll`)}
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">
                      {t("restaurantPerformance.total")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">
                      {t("restaurantPerformance.reserved")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">
                      {t("restaurantPerformance.free_booked")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">
                      {t("restaurantPerformance.available")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">
                      {t("restaurantPerformance.listed")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">
                      {t("restaurantPerformance.canceled")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">
                      {t("restaurantPerformance.released")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">
                      {t("restaurantPerformance.party_size")}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {report?.reportRestaurantPerformance.report.map((entry) => {
                  return (
                    <TableRow key={entry.reservationDate as string}>
                      <TableCell align="justify">
                        <Typography variant="body2">
                          {moment(Number(entry.reservationDate)).format(
                            "dddd , MMMM  D, YYYY",
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">
                          {entry.totalReservations}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">
                          {entry.reservedCount}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">
                          {entry.freeBookedCount}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">
                          {entry.availableCount}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">
                          {entry.listedCount}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">
                          {entry.canceledCount}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">
                          {entry.releasedCount}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">
                          {entry.totalMaxPartySize}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {loading ? <Spinner /> : null}
          {report?.reportRestaurantPerformance.report.length === 0 &&
            !loading && (
              <NoData
                topMargin="0"
                title={t("reservations.noDataTitle")}
                message={t("reservations.noDataText")}
              />
            )}
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            disabled={loading}
            count={report?.reportRestaurantPerformance.count ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            translate="yes"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <DateRangePicker
            save={handleSetDateRange}
            isOpen={isDateRangeModalOpen}
            handleClose={() => setIsDateRangeModalOpen(false)}
          />
        </Paper>
      </Container>
    </div>
  );
}
