import { SetStateAction, useContext } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Reservation } from "../../types/Reservation";
import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useTranslation } from "react-i18next";
import moment from "moment";
import MemberProfile from "../../components/common/MemberProfile";
import { getDateValue } from "../../utils/getDateValue.util";
import { AppContext } from "../../context/AppContext";
import SearchInput from "../../components/common/SearchInput";
import { Member } from "../../types";
import {
  BOOK_ON_BEHALF,
  CANCEL_ON_BEHALF,
  GET_MEMBER_BY_EMAIL,
} from "../../graphql";
import { useMutation, useQuery } from "@apollo/client";
import { ReservationStatus, Severity } from "../../constants";

export default function ReservationDetails({
  isOpen,
  setIsOpen,
  reservation,
  refresh,
}: {
  isOpen: boolean;
  setIsOpen: (value: SetStateAction<boolean>) => void;
  reservation: Reservation;
  refresh: () => void;
}) {
  const {
    data: member,
    loading,
    refetch,
  } = useQuery<{
    memberByEmail: Member;
  }>(GET_MEMBER_BY_EMAIL, {
    variables: {
      email: "",
    },
    fetchPolicy: "network-only",
  });
  const [bookOnBehalf, bookOnBehalfResponse] = useMutation(BOOK_ON_BEHALF);
  const [cancelOnBehalf, cancelOnBehalfResponse] =
    useMutation(CANCEL_ON_BEHALF);
  const { t } = useTranslation();
  const { restaurant, setSnackBarMessageProps } = useContext(AppContext);
  const toggleDrawer = (newOpen: SetStateAction<boolean>) => () => {
    setIsOpen(newOpen);
  };

  const mailtoSubject = encodeURIComponent(
    t("reservations.mailtoSubject", {
      location: restaurant?.name,
    }),
  );

  const mailtoBody = encodeURIComponent(
    t("reservations.mailtoBody", {
      table: reservation.table?.location + " → " + reservation.table?.label,
    }),
  );

  const width = window.innerWidth < 550 ? window.innerWidth - 20 : 550;
  const DrawerContent = (
    <Box sx={{ width }} role="presentation">
      <Container sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="right">
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Typography variant="h6">{t("reservations.titleDetail")}</Typography>
        <Paper sx={{ padding: 2, marginTop: 4 }} elevation={1}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">{t("reservations.date")}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Tooltip
                title={moment(getDateValue(reservation.date.start)).fromNow()}
                placement="top-end"
              >
                <Typography variant="body2">
                  {moment(getDateValue(reservation.date.start)).format(
                    "MMM DD, YYYY",
                  )}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ padding: 2, marginTop: 4 }} elevation={1}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">{t("reservations.time")}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body2">
                {moment(getDateValue(reservation.date.start)).format("h:mm A")}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ padding: 2, marginTop: 4 }} elevation={1}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">
                {t("reservations.guests")}
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body2">
                {reservation.table.maxPartySize}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ padding: 2, marginTop: 4 }} elevation={1}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">{t("reservations.table")}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body2">
                {reservation.table.location} &rarr; {reservation.table.label}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ padding: 2, marginTop: 4 }} elevation={1}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">
                {t("reservations.memberRestrictions")}
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body2">
                {reservation?.member?.restrictions?.join(", ") || "—"}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ padding: 2, marginTop: 4 }} elevation={1}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">
                {t("reservations.memberNote")}
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body2">
                {reservation.member?.note ?? "—"}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ padding: 2, marginTop: 4 }} elevation={1}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">
                {t("reservations.allergies")}
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body2">
                {reservation.member?.allergies?.join(", ") || "—"}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      {reservation?.member && <Divider sx={{ marginTop: 4 }} />}
      {reservation?.member ? (
        <Container sx={{ padding: 2 }}>
          <Typography variant="body1" paddingY={2}>
            {t("reservations.member")}
          </Typography>
          <MemberProfile member={reservation.member} />
        </Container>
      ) : (
        <Container sx={{ padding: 2 }}>
          <Typography variant="subtitle2" noWrap>
            Guest Reserved
          </Typography>{" "}
        </Container>
      )}
      {reservation?.member && <Divider sx={{ marginTop: 2 }} />}
      {reservation?.member && (
        <Container sx={{ padding: 2 }}>
          <a
            href={`mailto:${reservation.member?.email}?subject=${mailtoSubject}&body=${mailtoBody}`}
          >
            <Button color="primary">{t("reservations.contactMember")}</Button>
          </a>
        </Container>
      )}

      {reservation.status === ReservationStatus.AVAILABLE ||
      reservation.status === ReservationStatus.LISTED ? (
        <Container sx={{ padding: 2, marginTop: 4 }}>
          <SearchInput
            placeholder="Search member email"
            change={(email) => {
              refetch({
                email: email,
              });
            }}
          ></SearchInput>
          {member?.memberByEmail && (
            <Container sx={{ padding: 2 }}>
              <Typography variant="body1" paddingY={2}>
                {t("reservations.member")}
              </Typography>
              <MemberProfile member={member.memberByEmail} />
            </Container>
          )}
          <Stack sx={{ padding: 2, marginTop: 4 }} spacing={3} direction="row">
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disabled={!member?.memberByEmail}
              onClick={async () => {
                try {
                  const result = await bookOnBehalf({
                    variables: {
                      uuid: reservation.uuid,
                      memberUuid: member?.memberByEmail?.uuid,
                      free: false,
                    },
                  });

                  if (result) {
                    await refresh();
                    setSnackBarMessageProps?.({
                      message: t("reservations.booking_successful"),
                      severity: Severity.Success,
                    });
                  } else {
                    setSnackBarMessageProps?.({
                      message: t("reservations.booking_failed"),
                      severity: Severity.Error,
                    });
                  }
                } catch (error) {
                  setSnackBarMessageProps?.({
                    message: t("reservations.booking_failed", {
                      error: (error as Error).message,
                    }),
                    severity: Severity.Error,
                  });
                }
              }}
            >
              {t("reservations.book_on_behalf")}
            </Button>
            <Button
              variant="contained"
              color="success"
              size="medium"
              disabled={!member?.memberByEmail}
              onClick={async () => {
                try {
                  const result = await bookOnBehalf({
                    variables: {
                      uuid: reservation.uuid,
                      memberUuid: member?.memberByEmail?.uuid,
                      free: true,
                    },
                  });

                  if (result) {
                    await refresh();
                    setSnackBarMessageProps?.({
                      message: t("reservations.booking_successful"),
                      severity: Severity.Success,
                    });
                  } else {
                    setSnackBarMessageProps?.({
                      message: t("reservations.booking_failed"),
                      severity: Severity.Error,
                    });
                  }
                } catch (error) {
                  setSnackBarMessageProps?.({
                    message: t("reservations.booking_failed", {
                      error: (error as Error).message,
                    }),
                    severity: Severity.Error,
                  });
                }
              }}
            >
              {t("reservations.free_book")}
            </Button>
          </Stack>
        </Container>
      ) : (
        <Container sx={{ padding: 2, marginTop: 4 }}>
          <Stack sx={{ padding: 2, marginTop: 4 }} spacing={3} direction="row">
            <Button
              variant="contained"
              color="success"
              size="medium"
              onClick={async () => {
                try {
                  const result = await cancelOnBehalf({
                    variables: {
                      uuid: reservation.uuid,
                    },
                  });

                  if (result) {
                    await refresh();
                    setSnackBarMessageProps?.({
                      message: t("reservations.cancel_successful"),
                      severity: Severity.Success,
                    });
                  } else {
                    setSnackBarMessageProps?.({
                      message: t("reservations.cancel_failed"),
                      severity: Severity.Error,
                    });
                  }
                } catch (error) {
                  setSnackBarMessageProps?.({
                    message: t("reservations.cancel_failed", {
                      error: (error as Error).message,
                    }),
                    severity: Severity.Error,
                  });
                }
              }}
            >
              {t("reservations.cancel_on_behalf")}
            </Button>
          </Stack>
        </Container>
      )}
    </Box>
  );

  return (
    <div>
      <Drawer open={isOpen} onClose={toggleDrawer(false)} anchor="right">
        {DrawerContent}
      </Drawer>
    </div>
  );
}
