import { Gallery } from "@/app/types";
import { Delete, OpenWith, Star } from "@mui/icons-material";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";

import {
  Button,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { arrayMoveImmutable } from "array-move";

interface SortableItemParams {
  item: Gallery["items"][0];
  itemIndex: number;
  handleSetDefaultImage: (index: number) => void;
  isLoading: boolean;
  gallery: Gallery;
  setDeleteModal: (modal: { index: number; open: boolean }) => void;
}

const DragHandle = SortableHandle(() => (
  <IconButton sx={{ cursor: "move" }}>
    <OpenWith />
  </IconButton>
));

const SortableItem = SortableElement<SortableItemParams>(
  ({
    item,
    itemIndex,
    handleSetDefaultImage,
    isLoading,
    gallery,
    setDeleteModal,
  }: SortableItemParams) => (
    <ImageItem
      key={item.uuid}
      item={item}
      itemIndex={itemIndex}
      handleSetDefaultImage={handleSetDefaultImage}
      isLoading={isLoading}
      gallery={gallery}
      setDeleteModal={setDeleteModal}
    />
  ),
);

interface SortableListParams {
  items: Gallery["items"];
  handleSetDefaultImage: (index: number) => void;
  isLoading: boolean;
  gallery: Gallery;
  setDeleteModal: (modal: { index: number; open: boolean }) => void;
  onSortEnd: ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => void;
}

const SortableList = SortableContainer<SortableListParams>(
  ({
    items,
    handleSetDefaultImage,
    isLoading,
    gallery,
    setDeleteModal,
  }: SortableListParams) => {
    return (
      <ImageList cols={window.innerWidth < 768 ? 1 : 4} gap={20}>
        {items.map((item: Gallery["items"][0], index: number) => (
          <SortableItem
            key={item.uuid}
            item={item}
            itemIndex={index}
            index={index}
            handleSetDefaultImage={handleSetDefaultImage}
            isLoading={isLoading}
            gallery={gallery}
            setDeleteModal={setDeleteModal}
          />
        ))}
      </ImageList>
    );
  },
);

export default function PhotoList({
  gallery,
  handleSetDefaultImage,
  isLoading,
  setDeleteModal,
  setOrder,
}: {
  gallery: Gallery;
  handleSetDefaultImage: (index: number) => void;
  isLoading: boolean;
  setDeleteModal: (modal: { index: number; open: boolean }) => void;
  setOrder: (items: Gallery["items"]) => void;
}) {
  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    const items = arrayMoveImmutable(gallery.items, oldIndex, newIndex).map(
      (item, index) => ({
        ...item,
        order: index,
      }),
    );

    setOrder(items);
  };

  const items = gallery?.items || [];
  return (
    <SortableList
      items={items}
      handleSetDefaultImage={handleSetDefaultImage}
      isLoading={isLoading}
      gallery={gallery}
      axis="xy"
      useDragHandle
      setDeleteModal={setDeleteModal}
      onSortEnd={onSortEnd}
    />
  );
}

function ImageItem({
  item,
  itemIndex,
  handleSetDefaultImage,
  isLoading,
  gallery,
  setDeleteModal,
}: {
  item: Gallery["items"][0];
  itemIndex: number;
  handleSetDefaultImage: (index: number) => void;
  isLoading: boolean;
  gallery: Gallery;
  setDeleteModal: (modal: { index: number; open: boolean }) => void;
}) {
  const { t } = useTranslation();
  return (
    <ImageListItem
      id={item.uuid}
      key={item.uuid}
      sx={{
        position: "relative",
      }}
    >
      <img
        srcSet={`${item.image?.data}`}
        src={`${item.image?.data}`}
        alt={item.image?.alt}
        className="rounded-xl"
        loading="lazy"
      />
      <ImageListItemBar
        sx={{
          borderRadius: "12px 12px 0 0",
          background:
            "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
            "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
        }}
        position="top"
        actionIcon={
          <div>
            <div className="absolute left-0" title={t("common.move")}>
              <DragHandle />
            </div>
            <div className="justify-end text-right">
              <Button
                onClick={() => handleSetDefaultImage(itemIndex)}
                sx={{ color: "white" }}
                disabled={
                  isLoading || gallery?.primary?.data === item.image?.data
                }
                title={t("photoGallery.setDefault")}
                aria-label={t("photoGallery.setDefault")}
              >
                <Typography>{t("photoGallery.setDefault")}</Typography> <Star />
              </Button>
              <IconButton
                onClick={() => setDeleteModal({ index: itemIndex, open: true })}
                sx={{ color: "white" }}
                disabled={
                  isLoading || gallery?.primary?.data === item.image?.data
                }
                aria-label={t("common.delete")}
                title={t("common.delete")}
              >
                <Delete />
              </IconButton>
            </div>
          </div>
        }
      />
    </ImageListItem>
  );
}
