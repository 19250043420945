import {
  Alert,
  Box,
  Button,
  // Checkbox,
  // FormControlLabel,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { FormEvent, useContext, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";

import { initKeycloak, InitKeycloakParams } from "../../../keycloak";
import { AppContext, User } from "../../context/AppContext";
import { KEYCLOAK_REALM, UIMode } from "../../constants";
import logoLight from "../../../assets/images/logo-light.svg";
import logoDark from "../../../assets/images/logo-dark.svg";
import { useTranslation } from "react-i18next";
import { getAuthURL } from "../../utils/getBackendUrl.util";

export default function Login() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  // const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const { setCurrentUser, uiMode } = useContext(AppContext);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setError("");
      setIsLoading(true);
      const res = await fetch(
        `${getAuthURL()}/realms/${KEYCLOAK_REALM}/protocol/openid-connect/token`,
        {
          method: "POST",
          cache: "no-store",
          body: new URLSearchParams({
            username: email,
            password: password,
            grant_type: "password",
            client_id: KEYCLOAK_REALM,
          }),
        },
      );

      switch (res.status) {
        case 200:
        case 201:
        case 204:
          break;
        case 400:
        case 401:
        case 403:
          return setError(t("auth.invalidCredentials"));
        default:
          return setError(t("auth.loginFailed"));
      }

      const data: InitKeycloakParams = await res.json();
      const { keycloak } = await initKeycloak(data);
      setCurrentUser!(keycloak.tokenParsed! as User);
      if (keycloak.authenticated) {
        window.location.href = "/";
      }
    } catch (error) {
      console.error("Login failed", error);
      const message = t("auth.loginFailed");
      setError(`${message}: ${(error as Error).message ?? "Login failed"}`);
    } finally {
      setIsLoading(false);
    }
  };

  const logo = uiMode === UIMode.Dark ? logoDark : logoLight;
  return (
    <div className="m-[10%_auto] max-w-[690px]">
      <Box className="md:px-20 px-4 sm:m-2">
        <Box textAlign={"center"}>
          <img src={logo} alt="Logo" className="w-11 mx-auto mb-10" />
          <Typography
            variant="h2"
            marginBottom={2}
            fontFamily="Kessler"
            className="hidden md:block"
            flexWrap="wrap-reverse"
          >
            {t("auth.loginTitle")}
          </Typography>
          <Typography
            variant="h4"
            marginBottom={2}
            fontFamily="Kessler"
            className="block md:hidden"
            flexWrap="wrap-reverse"
          >
            {t("auth.loginTitle")}
          </Typography>
          <Typography variant="body1" marginBottom={4} className="md:px-7 px-4">
            {t("auth.loginDescription")}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <TextField
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label={t("auth.email")}
            required={true}
            variant="outlined"
            fullWidth
            type="email"
            placeholder="email@example.com"
            margin="normal"
          />

          <TextField
            id="password"
            label={t("auth.password")}
            variant="outlined"
            type="password"
            required={true}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            placeholder="*************"
            margin="normal"
          />

          <div className="my-4 flex align-middle items-center">
            <div className="flex-1">
              {/*  hide remember me */}
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={(e, checked) => setRememberMe(checked)}
                    name="jason"
                  />
                }
                label={t("auth.rememberMe")}
              /> */}
            </div>
            <Link href="/forgot-password" variant="body1">
              {t("auth.forgotPassword")}
            </Link>
          </div>

          <p className="mt-8 text-right">
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading}
              color="primary"
              sx={{ width: "100%" }}
            >
              {t("auth.login")}
            </Button>
          </p>

          {error && (
            <Alert
              icon={<ErrorIcon fontSize="inherit" />}
              className="mt-4"
              severity="error"
            >
              {error}
            </Alert>
          )}
        </form>
      </Box>
    </div>
  );
}
