import throttle from "lodash.throttle";
import {
  Box,
  Chip,
  Container,
  Grid,
  IconButton,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation } from "@apollo/client";
import { SyntheticEvent, useContext, useEffect, useRef, useState } from "react";

import {
  MenuCategory,
  MenuItem,
  NewMenuItemProps,
  TableHeader,
} from "@/app/types";
import { AppContext } from "../../context/AppContext";
import {
  CHANGE_CATEGORY_TITLE,
  CREATE_CATEGORY,
  CREATE_MENU_ITEM,
  REMOVE_CATEGORY,
  REMOVE_MENU_ITEM,
  UPDATE_MENU_ITEM,
} from "../../graphql";
import { Currency, Severity } from "../../constants";

import AccountHeader from "./AccountHeader";
import NewMenuModal from "../../components/profile/NewMenuModal";
import ConfirmModal from "../../components/common/ConfirmModal";
import PageSpinner from "../../components/common/PageSpinner";
import NoData from "../../components/common/NoData";
import { Edit } from "@mui/icons-material";
import MenuTable from "../../components/menu/MenuTable";

const CustomTab: any = styled((props) => <Tab disableRipple {...props} />)(
  () => ({
    minWidth: 0,
  }),
);

const CustomTabs: any = styled((props) => <Tabs {...props} />)(() => ({
  "& .MuiTabs-scroller": {
    overflowX: "scroll !important",
    marginRight: "80px",
  },
  minWidth: 0,
}));

function CustomTabPanel(props: {
  children: React.ReactNode;
  value: number;
  index: number;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const tableHeaders: TableHeader[] = [
  {
    key: "name",
    title: "menu.tableName",
  },
  {
    key: "description",
    title: "menu.tableDescription",
  },
  {
    key: "price",
    title: "menu.tablePrice",
  },
];

export default function MenuPage() {
  const [createCategory, createCategoryResponse] = useMutation(CREATE_CATEGORY);
  const [changeCategoryTitle, changeCategoryResponse] = useMutation(
    CHANGE_CATEGORY_TITLE,
  );

  const [removeCategory, removeCategoryResponse] = useMutation(REMOVE_CATEGORY);

  const [removeMenuItem, removeMenuItemResponse] =
    useMutation(REMOVE_MENU_ITEM);

  const [createMenuItem, createMenuItemResponse] =
    useMutation(CREATE_MENU_ITEM);

  const [updateMenuItem, updateMenuItemResponse] =
    useMutation(UPDATE_MENU_ITEM);

  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const [isNewMenuModalOpen, setIsNewMenuModalOpen] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [editCategoryUuid, setEditCategoryUuid] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { restaurant, setSnackBarMessageProps, setCategories, categories } =
    useContext(AppContext);
  const [newMenuItemProps, setNewMenuItemProps] =
    useState<NewMenuItemProps | null>(null);
  const inputRef = useRef(null);
  const throttled = useRef(
    throttle(
      (newValue: NewMenuItemProps | null, categories?: MenuCategory[] | null) =>
        handleMenuItemChange(newValue, categories),
      2000,
    ),
  );

  const isLoading =
    createCategoryResponse.loading ||
    removeCategoryResponse.loading ||
    removeMenuItemResponse.loading ||
    createMenuItemResponse.loading ||
    changeCategoryResponse.loading ||
    updateMenuItemResponse.loading;

  useEffect(() => {
    throttled.current(newMenuItemProps, categories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMenuItemProps]);

  const handleUpdateMenuContent = async (
    menuItemProps: NewMenuItemProps | null,
  ) => {
    if (!menuItemProps) {
      return;
    }

    const { targetValue, categoryIndex, index, key } = menuItemProps;

    const value: {
      [key: string]: string | number | { amount: number; currency: Currency };
    } = {};
    const currentMenuItem = categories?.[categoryIndex]?.items?.[index];

    switch (key) {
      case "price":
        value[key] = {
          amount: parseFloat(targetValue.toString()),
          currency: currentMenuItem?.price.currency ?? Currency.USD,
        };
        break;
      default:
        value[key] = targetValue;
        break;
    }

    setCategories!((categories) =>
      categories?.map((category, idx) => {
        if (idx === categoryIndex) {
          return {
            ...category,
            items: category.items.map((item, rowIndex) => {
              if (rowIndex === index) {
                return {
                  ...item,
                  ...value,
                };
              }
              return item;
            }),
          };
        }
        return category;
      }),
    );

    setNewMenuItemProps(menuItemProps);
  };

  const handleMenuItemChange = async (
    menuItemProps: NewMenuItemProps | null,
    categories?: MenuCategory[] | null,
  ) => {
    if (!menuItemProps) {
      return;
    }

    const { targetValue, categoryIndex, index, key } = menuItemProps;
    const currentMenuItem = categories?.[categoryIndex].items[index];
    const price =
      (key === "price"
        ? parseFloat(targetValue.toString())
        : currentMenuItem?.price.amount) ?? 0;
    const order =
      (key === "order"
        ? parseInt(targetValue.toString(), 10)
        : currentMenuItem?.order) ?? 0;

    try {
      await updateMenuItem?.({
        variables: {
          uuid: currentMenuItem?.uuid,
          ...currentMenuItem,
          currency: currentMenuItem?.price.currency,
          [key]: targetValue,
          order: order < 0 || !order ? 0 : order,
          price: price < 0 || !price ? 0 : price,
        },
      });
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("menu.editError", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });
    }
  };

  function tabProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  async function handleRemoveMenuItem(categoryIndex: number, index: number) {
    try {
      await removeMenuItem({
        variables: { uuid: categories?.[categoryIndex].items[index].uuid },
      });
      setCategories?.((categories) =>
        categories?.map((category, idx) => {
          if (idx === categoryIndex) {
            return {
              ...category,
              items: category.items.filter((_, rowIndex) => rowIndex !== index),
            };
          }
          return category;
        }),
      );
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("menu.deleteError", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });
    }
  }

  async function addMenuItem(categoryIndex: number) {
    const newMenuItem = {
      uuid: "",
      name: "",
      description: "",
      order: categories?.[categoryIndex].items.length ?? 0 + 1,
      price: {
        amount: 0,
        currency: Currency.USD,
      },
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    try {
      const res = await createMenuItem?.({
        variables: {
          categoryUUID: categories?.[categoryIndex].uuid,
          ...newMenuItem,
          price: newMenuItem.price.amount,
          currency: newMenuItem.price.currency,
        },
      });
      newMenuItem.uuid = res?.data?.createMenuItem?.uuid;
      setCategories?.((categories) =>
        categories?.map((category, idx) => {
          if (idx === categoryIndex) {
            return {
              ...category,
              items: [...category.items, newMenuItem],
            };
          }
          return category;
        }),
      );

      setTimeout(() => {
        (inputRef?.current as any)?.childNodes?.[0]?.focus?.();
      }, 250);
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("menu.addError", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });
    }
  }

  const handleChange = (event: SyntheticEvent, tab: number) => {
    setTab(tab);
  };

  const handleDeleteTab = async (tab: number) => {
    try {
      await removeCategory({ variables: { uuid: categories?.[tab].uuid } });
      setCategories?.((categories) =>
        categories?.filter((_, index) => index !== tab),
      );
      setTab(tab > 0 ? tab - 1 : 0);
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("menu.deleteError", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });
    }
  };

  const setOrder = async (categoryIndex: number, items: MenuItem[]) => {
    try {
      items.forEach((item, index) => {
        updateMenuItem?.({
          variables: {
            ...item,
            price: item.price.amount,
            currency: item.price.currency,
            order: index,
          },
        });
      });

      setCategories?.((categories) =>
        categories?.map((category, idx) => {
          if (idx === categoryIndex) {
            return {
              ...category,
              items,
            };
          }
          return category;
        }),
      );
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("menu.orderError", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });
    }
  };

  const createNewCategory = (e: SyntheticEvent) => {
    handleChange(e, (categories?.length ?? 1) - 1);
    setIsNewMenuModalOpen(true);
  };

  const updateCategory = () => {
    setIsNewMenuModalOpen(true);
    setCategoryName(categories?.[tab].title ?? "");
    setEditCategoryUuid(categories?.[tab].uuid ?? "");
  };

  const createMenu = async (title: string, uuid?: string): Promise<unknown> => {
    if (uuid) {
      try {
        await changeCategoryTitle?.({
          variables: {
            uuid,
            title,
          },
        });

        setIsNewMenuModalOpen(false);
        setEditCategoryUuid("");
        return setCategories?.((categories) =>
          categories?.map((category, idx) => {
            if (idx === tab) {
              return {
                ...category,
                title,
              };
            }
            return category;
          }),
        );
      } catch (error) {
        console.error(error);
        setSnackBarMessageProps?.({
          message: t("menu.editError", {
            error: (error as Error).message,
          }),
          severity: Severity.Error,
        });

        return error;
      }
    }

    try {
      const { errors, data } = await createCategory({
        variables: { title, uuid: restaurant!.uuid },
      });
      if (errors) {
        throw errors;
      }
      setCategories?.((categories) => [
        ...categories!,
        {
          uuid: data.createCategory.uuid,
          createdAt: new Date(),
          items: [],
          title,
        },
      ]);
      setTab(categories?.length ?? 0);
      setIsNewMenuModalOpen(false);
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("menu.addError", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });

      return error;
    }
  };

  if (!restaurant) {
    return <PageSpinner />;
  }

  return (
    <div>
      <AccountHeader />
      <Container maxWidth="xl">
        <Grid item xs={12} md={9} sx={{ my: 1 }}>
          <div className="flex gap-2">
            <div className="flex-1">
              <Typography variant="h6">{restaurant.name}</Typography>
              <Typography variant="body1">
                {[restaurant.city?.name, restaurant.city?.location]
                  .filter(Boolean)
                  .join(", ")}{" "}
                {restaurant.city?.country &&
                  t(`countries.${restaurant.city.country}`)}
              </Typography>
            </div>
            <div className="justify-end md:mr-5">
              <Chip
                label={t(`restaurants.listed-${restaurant.listed}`)}
                color={restaurant.listed ? "success" : "error"}
              />
            </div>
          </div>
        </Grid>
        <Grid container className="my-8">
          <Grid item xs={12} md={9} sx={{ my: 1 }}>
            {!categories?.length ? (
              <NoData
                topMargin="10%"
                title={t("menu.noDataTitle")}
                message={t("menu.noDataText")}
                action={() => setIsNewMenuModalOpen(true)}
                actionTitle={t("menu.addMenu")}
              />
            ) : (
              <Box>
                <Typography variant="h6">{t("menu.title")}</Typography>
                <Typography variant="caption">
                  {t("common.autosaveNotice")}
                </Typography>
              </Box>
            )}
          </Grid>

          <Grid item xs={12} md={9} sx={{ my: 1 }}>
            {categories?.length ? (
              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                position="relative"
              >
                <CustomTabs
                  value={tab}
                  onChange={handleChange}
                  aria-label={t("menu.title")}
                >
                  {categories?.map((category, index) => (
                    <CustomTab
                      label={category.title}
                      key={index}
                      {...tabProps(index)}
                    />
                  ))}
                  <CustomTab
                    icon={<AddIcon />}
                    {...tabProps(categories.length)}
                    onClick={isLoading ? null : createNewCategory}
                  />
                </CustomTabs>
                {categories.length > 0 && (
                  <div>
                    <IconButton
                      sx={{ position: "absolute", right: 0, top: 0 }}
                      disabled={isLoading}
                      onClick={() => setIsDeleteModalOpen(true)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      sx={{ position: "absolute", right: 40, top: 0 }}
                      disabled={isLoading}
                      onClick={() => updateCategory()}
                    >
                      <Edit />
                    </IconButton>
                  </div>
                )}
              </Box>
            ) : null}
          </Grid>

          <Grid item xs={12} md={9} sx={{ my: 1 }}>
            {categories?.length ? (
              <CustomTabPanel value={tab} index={tab}>
                <TableContainer>
                  <Table aria-label={categories?.[tab]?.title}>
                    <TableHead>
                      <TableRow>
                        <TableCell width={5}></TableCell>
                        {tableHeaders.map((header) => (
                          <TableCell key={header.key}>
                            {t(header.title)}
                          </TableCell>
                        ))}
                        <TableCell>&nbsp;</TableCell>
                      </TableRow>
                    </TableHead>
                    <MenuTable
                      categories={categories}
                      tab={tab}
                      isLoading={isLoading}
                      handleRemoveMenuItem={handleRemoveMenuItem}
                      handleUpdateMenuContent={handleUpdateMenuContent}
                      addMenuItem={addMenuItem}
                      inputRef={inputRef}
                      setOrder={setOrder}
                      tableHeaders={tableHeaders}
                    />
                  </Table>
                </TableContainer>
              </CustomTabPanel>
            ) : null}
            <NewMenuModal
              save={createMenu}
              editCategoryUuid={editCategoryUuid}
              defaultName={categoryName}
              isOpen={isNewMenuModalOpen}
              handleClose={() => {
                setIsNewMenuModalOpen(false);
                setEditCategoryUuid("");
              }}
            />
            {isDeleteModalOpen && (
              <ConfirmModal
                actionTitle={t("common.delete")}
                title={t("menu.delete")}
                inputIsOpen={isDeleteModalOpen}
                inputHandleClose={() => setIsDeleteModalOpen(false)}
                message={t("menu.deleteMessage")}
                handleConfirm={() => handleDeleteTab(tab)}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
