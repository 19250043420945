import { Member } from "@/app/types";
import {
  ArrowOutward,
  Delete,
  Edit,
  Key,
  MoreVert,
  RemoveRedEye,
} from "@mui/icons-material";
import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type MenuItemData = {
  key: string;
  label: string;
  disabled?: boolean;
};

const menuItems: MenuItemData[] = [
  {
    key: "view",
    label: "members.viewAccount",
  },
  {
    key: "credits",
    label: "members.manageCredits",
  },

  {
    key: "separator",
    label: "separator",
  },
  {
    key: "edit",
    label: "members.edit",
  },
  {
    key: "view-application",
    label: "members.viewApplication",
  },
  {
    key: "separator",
    label: "separator",
  },
  {
    key: "delete",
    label: "members.delete",
    disabled: true,
  },
];

const renderMenuItem = (
  member: Member,
  item: MenuItemData,
  index: number,
  t: (key: string) => string,
  handleClose: (key: string) => void,
) => {
  switch (true) {
    case item.key === "separator":
      return <Divider key={index} />;
    case item.key === "view-application" && member.active:
      return null;
    default:
      return (
        <MenuItem
          disabled={item.disabled}
          key={item.key}
          onClick={() => handleClose(item.key)}
        >
          <ListItemIcon>{getIcon(item.key)}</ListItemIcon>
          <Typography>{t(item.label)}</Typography>
        </MenuItem>
      );
  }
};

const getIcon = (key: string) => {
  switch (key) {
    case "view":
      return <ArrowOutward fontSize="small" />;
    case "credits":
      return <Key fontSize="small" />;
    case "edit":
      return <Edit fontSize="small" />;
    case "delete":
      return <Delete fontSize="small" />;
    case "view-application":
      return <RemoveRedEye fontSize="small" />;
    default:
      return null;
  }
};
export function MemberMenu({
  member,
  handleShowDelete,
  handleShowManageKeys,
  handleShowDetail,
  handleViewApplication,
  handleShowEdit,
}: {
  member: Member;
  handleShowEdit: (member: Member) => void;
  handleShowDelete: (member: Member) => void;
  handleShowManageKeys: (member: Member) => void;
  handleShowDetail?: (member: Member) => void;
  handleViewApplication?: (member: Member) => void;
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (key?: string) => {
    setAnchorEl(null);
    switch (key) {
      case "view":
        handleShowDetail && handleShowDetail(member);
        break;
      case "edit":
        handleShowEdit(member);
        break;
      case "credits":
        handleShowManageKeys(member);
        break;
      case "delete":
        handleShowDelete(member);
        break;
      case "view-application":
        handleViewApplication && handleViewApplication(member);
        break;
    }
  };

  const getId = (id: string) => `menu-${member.uuid}-${id}`;
  return (
    <div>
      <IconButton
        aria-label={t("members.openMenu")}
        id={getId("button")}
        aria-controls={open ? getId("menu") : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id={getId("menu")}
        MenuListProps={{
          "aria-labelledby": getId("button"),
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
      >
        {menuItems.map((item, index) =>
          renderMenuItem(member, item, index, t, handleClose),
        )}
      </Menu>
    </div>
  );
}
