import {
  ChangeEvent,
  MouseEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { t } from "i18next";
import Paper from "@mui/material/Paper";

import AddIcon from "@mui/icons-material/Add";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  Container,
  Unstable_Grid2 as Grid,
  TableSortLabel,
  Typography,
  Table,
  TablePagination,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Chip,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import SearchInput from "../../components/common/SearchInput";
import MemberEdit from "./MemberEdit";
import NoData from "../../components/common/NoData";
import { Member } from "@/app/types/Member";
import ConfirmModal from "../../components/common/ConfirmModal";
import MemberProfile from "../../components/common/MemberProfile";
import { AppContext } from "../../context/AppContext";
import MemberEditCredits from "./MemberEditCredits";
import { GET_MEMBERS } from "../../graphql";
import moment from "moment";
import { MemberMenu } from "./MemberMenu";
import Spinner from "../../components/common/Spinner";
import MemberApplication from "./MemberApplication";

interface Column {
  id:
    | "preferredName"
    | "membershipId"
    | "joinDate"
    | "balance"
    | "reservations"
    | "active"
    | "actions";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value?: number | string) => string;
}

const columns: readonly Column[] = [
  { id: "preferredName", label: t("members.name"), minWidth: 170 },
  {
    id: "membershipId",
    label: t("members.idTitle"),
    minWidth: 100,
  },
  {
    id: "balance",
    label: t("members.credits"),
    minWidth: 100,
  },
  // {
  //   id: "reservations",
  //   label: t("members.reservations"),
  //   minWidth: 70,
  // },
  {
    id: "joinDate",
    label: t("members.joinDate"),
    minWidth: 70,
  },
  {
    id: "active",
    label: t("members.status"),
    minWidth: 70,
  },
  {
    id: "actions",
    label: "",
    align: "right",
    minWidth: 200,
  },
];

enum Order {
  ASC = "asc",
  DESC = "desc",
}

enum RowFields {
  NAME = "preferredName",
  // RESERVATIONS = "reservations",
  BALANCE = "balance",
  JOIN_DATE = "joinDate",
}

function descendingComparator(a: Member, b: Member, orderBy: RowFields) {
  if (b[orderBy]! < a[orderBy]!) {
    return -1;
  }

  if (b[orderBy]! > a[orderBy]!) {
    return 1;
  }

  return 0;
}

function getComparator(order: Order, orderBy: RowFields) {
  return order === Order.DESC
    ? (a: Member, b: Member) => descendingComparator(a, b, orderBy)
    : (a: Member, b: Member) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: Member[], comparator: Function) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }

    switch (typeof a[1]) {
      case "number":
        return a[1] - Number(b[1]);
      case "string":
        return String(a[1]).localeCompare(b[1].toString());
      default:
        return a[1] < b[1] ? -1 : 1;
    }
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Members() {
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const {
    setMember: setAppContextMember,
    setMembers,
    members,
  } = useContext(AppContext);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState(Order.ASC);
  const [orderBy, setOrderBy] = useState<RowFields | null>(null);
  const [searchText, setSearchText] = useState("");
  const [member, setMember] = useState<Member | null>(null);
  const [cachedMember, setCachedMember] = useState<Member | null>(null);
  const [showDelete, setShowDelete] = useState(false);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [isCreditsOpen, setIsCreditsOpen] = useState(false);
  const [isMemberApplicationOpen, setIsMemberApplicationOpen] = useState(false);

  const {
    data: membersData,
    refetch,
    loading,
  } = useQuery<{
    members: { items: Member[]; count: number };
  }>(GET_MEMBERS, {
    variables: {
      limit: rowsPerPage,
      offset: page * rowsPerPage,
    },
  });

  useEffect(() => {
    if (membersData?.members?.items) {
      setMembers?.(membersData.members.items);
    }
  }, [membersData?.members?.items, setMembers]);

  const handleChangePage = async (event: unknown, newPage: number) => {
    await refetch({
      limit: rowsPerPage,
      offset: newPage * rowsPerPage,
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    await refetch({
      limit: newRowsPerPage,
      offset: 0,
    });
    setPage(0);
  };

  const handleCreateMember = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    const newMember: Partial<Member> = cachedMember || {};
    handleShowEdit(newMember as Member);
  };

  const handleShowEdit = (row: Member) => {
    setMember(row);
    setIsDetailOpen(true);
  };

  const getRowValue = (row: Member, column: Column) => {
    const { id: key, format } = column;
    if (format) {
      return format(row[key as keyof Member].toString());
    }

    const value = row[key as keyof Member];
    switch (key) {
      case "active":
        return t(`members.active-${value ?? "false"}`);
      case "joinDate":
        return value
          ? moment(new Date(Number(value))).format("MMM, DD YYYY")
          : "-";
      default:
        return value ?? "-";
    }
  };

  const handleShowDetail = (row: Member) => {
    setAppContextMember!(row);
    navigate(`/members/${row.uuid}`);
  };

  const handleShowManageKeys = (row: Member) => {
    setAppContextMember!(row);
    setMember(row);
    setIsCreditsOpen(true);
  };

  const handleShowDelete = (row: Member) => {
    setMember(row);
    setShowDelete(true);
  };

  const handleDeleteRestaurant = () => {
    if (member) {
      const newMembers = members?.filter((r) => r.uuid !== member.uuid);
      setMembers?.(newMembers);
      setShowDelete(false);
    }
  };

  const handleViewApplication = (row: Member) => {
    setIsMemberApplicationOpen(true);
    setMember(row);
  };

  const createSortHandler =
    (property: RowFields) => (event: MouseEvent<HTMLAnchorElement>) => {
      const isAsc = orderBy === property && order === Order.ASC;
      setOrder(isAsc ? Order.DESC : Order.ASC);
      setOrderBy(property);
    };

  const visibleRows = (
    useMemo(
      () => stableSort(members ?? [], getComparator(order, orderBy!)),
      [members, order, orderBy],
    ) as Member[]
  ).filter((row) => {
    return (
      !searchText ||
      new RegExp(searchText, "ig").test(
        `${row.firstName} ${row.lastName} ${row.preferredName} ${row.email} ${row.membershipId} ${row.uuid}`,
      )
    );
  });

  const tableContent = (
    <Paper>
      <TableContainer sx={{ maxHeight: "60vh" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sortDirection={orderBy === column.id ? order : false}
                  style={{ minWidth: column.minWidth }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : Order.ASC}
                    onClick={createSortHandler(column.id as RowFields)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === Order.DESC
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row, i) => {
              return (
                <TableRow hover tabIndex={-1} key={i}>
                  {columns.map((column) => {
                    switch (column.id) {
                      case RowFields.NAME:
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <MemberProfile member={row}></MemberProfile>
                          </TableCell>
                        );
                      case "actions":
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <MemberMenu
                              member={row}
                              handleShowEdit={handleShowEdit}
                              handleShowDelete={handleShowDelete}
                              handleShowManageKeys={handleShowManageKeys}
                              handleShowDetail={handleShowDetail}
                              handleViewApplication={handleViewApplication}
                            />
                          </TableCell>
                        );
                      default:
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "active" ? (
                              <Chip
                                label={getRowValue(row, column).toString()}
                                color={row.active ? "success" : "error"}
                              ></Chip>
                            ) : (
                              getRowValue(row, column).toString()
                            )}
                          </TableCell>
                        );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        disabled={loading}
        count={membersData?.members.count ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        translate="yes"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );

  const content = loading ? (
    <Spinner />
  ) : visibleRows.length ? (
    tableContent
  ) : (
    <NoData
      title={t("members.noDataTitle")}
      message={t("members.noDataText")}
    />
  );
  return (
    <div>
      <Container maxWidth="xl">
        <div className="flex justify-end my-8">
          <Button onClick={handleCreateMember} variant="contained">
            <AddIcon /> {t("members.create")}
          </Button>
        </div>
        <Grid container>
          <Grid sx={{ my: 1 }} xs={12} md={9}>
            <Typography variant="h6" noWrap>
              {t("members.title")}
              <Chip
                label={membersData?.members?.count ?? "—"}
                color="primary"
                sx={{ ml: 1 }}
              />
            </Typography>
          </Grid>
          <Grid xs={12} md={3} sx={{ my: { xs: 2 } }}>
            <SearchInput
              placeholder={t("members.search")}
              change={setSearchText}
            ></SearchInput>
          </Grid>
        </Grid>
        {showDelete && member && (
          <ConfirmModal
            inputIsOpen={showDelete}
            title={t("members.delete")}
            message={t("members.deleteText")}
            actionTitle={t("common.delete")}
            inputHandleClose={() => setShowDelete(false)}
            handleConfirm={handleDeleteRestaurant}
          />
        )}
        {isCreditsOpen && member && (
          <MemberEditCredits
            isOpen={isCreditsOpen}
            member={member}
            setIsOpen={setIsCreditsOpen}
          />
        )}
        {isMemberApplicationOpen && member && (
          <MemberApplication
            isOpen={isMemberApplicationOpen}
            member={member}
            setIsOpen={setIsMemberApplicationOpen}
          />
        )}
        {isDetailOpen && member && (
          <MemberEdit
            isOpen={isDetailOpen}
            setCachedMember={setCachedMember}
            member={member}
            setIsOpen={setIsDetailOpen}
          />
        )}
        {content}
      </Container>
    </div>
  );
}
