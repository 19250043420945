import { useMutation } from "@apollo/client";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Unstable_Grid2 as Grid,
  IconButton,
  ImageListItem,
  Paper,
  Typography,
} from "@mui/material";

import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import AccountHeader from "./AccountHeader";
import Preview from "./Preview";
import NoData from "../../components/common/NoData";
import GettingStarted, { setupItems } from "./GettingStarted";
import PageSpinner from "../../components/common/PageSpinner";
import ImagePreviewModal from "../../components/common/ImagePreviewModal";
import { Gallery, MenuCategory, Restaurant } from "../../types";
import { Severity } from "../../constants";
import ConfirmModal from "../../components/common/ConfirmModal";
import { getRestaurantRoutePrefix } from "../../utils/getRestaurantRoutePrefix.util";
import { AppData } from "../../components/shared/AppData";
import Spinner from "../../components/common/Spinner";

import { UPDATE_RESTAURANT_INFORMATION } from "../../graphql";
import { getRestaurantPayload } from "../../utils/getRestaurantPayload.util";

const sliderProperties = {
  prevArrow: (
    <IconButton style={{ left: -40 }}>
      <KeyboardArrowLeftIcon />
    </IconButton>
  ),
  nextArrow: (
    <IconButton style={{ right: -40 }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  ),
};

const getCurrentStep = (
  restaurant?: Restaurant,
  resyValid?: boolean,
  categories?: MenuCategory[],
  gallery?: Gallery,
) => {
  switch (true) {
    case !gallery?.items?.length:
      return 0;
    case !categories?.length:
      return 1;
    case !restaurant?.details.arrivalPolicy:
      return 2;
    case !restaurant?.details.cancellationPolicy:
      return 3;
    case !resyValid:
      return 4;
    default:
      return 5;
  }
};

export default function Profile() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateRestaurantInformation] = useMutation(
    UPDATE_RESTAURANT_INFORMATION,
  );
  const {
    restaurant,
    categories,
    setRestaurant,
    gallery,
    resyValid,
    isGlobalLoading,
    setSnackBarMessageProps,
  } = useContext(AppContext);
  const currentStepIndex = getCurrentStep(
    restaurant,
    resyValid,
    categories,
    gallery,
  );
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isConfirmStatusOpen, setIsConfirmStatusOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const handleStatusChange = async () => {
    if (!restaurant) {
      return;
    }

    try {
      const variables = getRestaurantPayload({
        ...restaurant,
        listed: !restaurant.listed,
      });
      const { data, errors } = await updateRestaurantInformation({
        variables,
      });
      if (data?.updateRestaurantInformation) {
        setRestaurant?.({
          ...restaurant,
          listed: data.updateRestaurantInformation.listed,
        });
        setSnackBarMessageProps?.({
          message: t("common.success"),
          severity: Severity.Success,
        });
        setIsConfirmStatusOpen(false);
      } else {
        throw errors;
      }
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("common.error"),
        severity: Severity.Error,
      });
    }
  };

  if (!restaurant) {
    return null;
  }

  return (
    <div>
      {id && <AppData uuid={id} />}
      {restaurant ? (
        <div>
          <AccountHeader />
          <Preview
            isOpen={isPreviewOpen}
            restaurant={restaurant}
            gallery={gallery!}
            setIsOpen={setIsPreviewOpen}
          />
          <ImagePreviewModal
            isOpen={!!currentImage}
            handleClose={() => setCurrentImage("")}
            url={currentImage}
          />
          {isConfirmStatusOpen && (
            <ConfirmModal
              title={t("account.statusChangeTitle")}
              actionColor={
                restaurant.listed ? Severity.Error : Severity.Success
              }
              actionTitle={t(
                `account.${restaurant.listed ? "unlistAction" : "listAction"}`,
              )}
              inputIsOpen={isConfirmStatusOpen}
              inputHandleClose={() => setIsConfirmStatusOpen(false)}
              message={t("account.statusChangeText", {
                status: t(`restaurants.listed-${restaurant.listed}`),
                newStatus: t(`restaurants.listed-${!restaurant.listed}`),
              })}
              handleConfirm={() => handleStatusChange()}
            />
          )}

          <Container maxWidth="xl">
            <Grid container marginTop={4}>
              <Grid xs={12} sm={7}>
                <div className="flex gap-2">
                  <div className="flex-1">
                    <Typography variant="h6">{restaurant.name}</Typography>
                    <Typography variant="body1">
                      {[restaurant.city?.name, restaurant.city?.location]
                        .filter(Boolean)
                        .join(", ")}{" "}
                      {restaurant.city?.country &&
                        t(`countries.${restaurant.city.country}`)}
                    </Typography>
                  </div>
                  <div className="justify-end md:mr-5">
                    <Chip
                      label={t(`restaurants.listed-${restaurant.listed}`)}
                      color={restaurant.listed ? "success" : "error"}
                    />
                  </div>
                </div>
              </Grid>
              <Grid
                xs={12}
                sm={5}
                className="md:text-right mt-10 md:mt-0 text-left"
              >
                <div className="flex gap-2 justify-end">
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setIsPreviewOpen(true)}
                  >
                    {t("account.previewAction")}
                  </Button>
                  <Button
                    variant="outlined"
                    color={restaurant.listed ? "error" : "success"}
                    onClick={() => setIsConfirmStatusOpen(true)}
                  >
                    {t(
                      `account.${restaurant.listed ? "unlistAction" : "listAction"}`,
                    )}
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid container marginTop={4}>
              <Grid xs={12} sm={7}>
                <Box marginRight={2}>
                  <Paper elevation={3} className="p-8">
                    <Grid container>
                      <Grid xs={12} sm={6}>
                        <Typography variant="h6">
                          {t("account.accountInfoTitle")}
                        </Typography>
                      </Grid>
                      <Grid xs={12} sm={6} className="md:text-right">
                        <Link to={getRestaurantRoutePrefix(restaurant, "edit")}>
                          <Button variant="contained" color="secondary">
                            {t("common.edit")}
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>

                    <Box marginTop={6}>
                      <Grid container>
                        <Grid xs={12} sm={6}>
                          <Typography variant="body1">
                            {t("account.restaurantName")}
                          </Typography>
                        </Grid>
                        <Grid xs={12} sm={6} className="md:text-right">
                          <Typography variant="body2">
                            {restaurant.name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider sx={{ marginTop: 2 }} />
                    </Box>
                    <Box marginTop={4}>
                      <Grid container>
                        <Grid xs={12} sm={6}>
                          <Typography variant="body1">
                            {t("profileEdit.description")}
                          </Typography>
                        </Grid>
                        <Grid xs={12} sm={6} className="md:text-right">
                          <Typography variant="body2">
                            {restaurant.details.description}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider sx={{ marginTop: 2 }} />
                    </Box>
                    <Box marginTop={4}>
                      <Grid container>
                        <Grid xs={12} sm={6}>
                          <Typography variant="body1">
                            {t("profileEdit.notes")}
                          </Typography>
                        </Grid>
                        <Grid xs={12} sm={6} className="md:text-right">
                          <Typography variant="body2">
                            {restaurant.notes}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider sx={{ marginTop: 2 }} />
                    </Box>

                    <Box marginTop={4}>
                      <Grid container>
                        <Grid xs={12} sm={6}>
                          <Typography variant="body1">
                            {t("account.restaurantCuisine")}
                          </Typography>
                        </Grid>
                        <Grid xs={12} sm={6} className="md:text-right">
                          <Typography variant="body2">
                            {restaurant.cuisine?.join?.(", ")}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider sx={{ marginTop: 2 }} />
                    </Box>
                    <Box marginTop={4}>
                      <Grid container>
                        <Grid xs={12} sm={6}>
                          <Typography variant="body1">
                            {t("profileEdit.contactName")}
                          </Typography>
                        </Grid>
                        <Grid xs={12} sm={6} className="md:text-right">
                          <Typography variant="body2">
                            {restaurant.contact.name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider sx={{ marginTop: 2 }} />
                    </Box>
                    <Box marginTop={4}>
                      <Grid container>
                        <Grid xs={12} sm={6}>
                          <Typography variant="body1">
                            {t("account.restaurantWebsite")}
                          </Typography>
                        </Grid>
                        <Grid xs={12} sm={6} className="md:text-right">
                          <Typography variant="body2">
                            {restaurant.contact.website}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider sx={{ marginTop: 2 }} />
                    </Box>
                    <Box marginTop={4}>
                      <Grid container>
                        <Grid xs={12} sm={6}>
                          <Typography variant="body1">
                            {t("profileEdit.instagram")}
                          </Typography>
                        </Grid>
                        <Grid xs={12} sm={6} className="md:text-right">
                          <Typography variant="body2">
                            {restaurant.contact.instagram ?? "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider sx={{ marginTop: 2 }} />
                    </Box>
                    <Box marginTop={4}>
                      <Grid container>
                        <Grid xs={12} sm={6}>
                          <Typography variant="body1">
                            {t("account.restaurantPhone")}
                          </Typography>
                        </Grid>
                        <Grid xs={12} sm={6} className="md:text-right">
                          <Typography variant="body2">
                            {restaurant.contact.phone}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                  <Box marginY={2}>
                    <Accordion
                      expanded={isExpanded}
                      onChange={(e, value) => setIsExpanded(value)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{ paddingX: 4, py: 2 }}
                      >
                        <Typography variant="body2">
                          {t("account.gettingStartedText", {
                            current: currentStepIndex,
                            total: setupItems.length,
                          })}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ paddingX: 4 }}>
                        <GettingStarted
                          restaurant={restaurant}
                          currentStepIndex={currentStepIndex}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} sm={5}>
                <Paper elevation={3} className="p-8 sm:ml-4">
                  {gallery?.items?.length ? (
                    <Grid container>
                      <Grid xs={12} sm={6}>
                        <Typography variant="h6">
                          {t("account.photoGalleryTitle")}
                        </Typography>
                      </Grid>
                      <Grid xs={12} sm={6} className="md:text-right">
                        <Link
                          to={getRestaurantRoutePrefix(
                            restaurant,
                            "photo-gallery",
                          )}
                        >
                          <Button
                            variant="contained"
                            color="secondary"
                            type="button"
                          >
                            {t("account.manageAction")}
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  ) : null}
                  <Box marginTop={4} padding="0 30px">
                    {gallery?.items?.length ? (
                      <Slide
                        slidesToScroll={2}
                        slidesToShow={2}
                        {...sliderProperties}
                      >
                        {gallery?.items.map((item, index) => (
                          <div className="each-slide-effect mx-1" key={index}>
                            <ImageListItem>
                              <div
                                role="button"
                                onClick={() => setCurrentImage(item.image.data)}
                                title={item.image.alt}
                                className="w-full"
                                style={{
                                  height: "240px",
                                  background: `url(${item.image.data}) center no-repeat `,
                                  backgroundSize: "cover",
                                }}
                              ></div>
                            </ImageListItem>
                          </div>
                        ))}
                      </Slide>
                    ) : isGlobalLoading ? (
                      <Spinner />
                    ) : (
                      <NoData
                        title={t("photoGallery.noPhotosTitle")}
                        message={t("photoGallery.noPhotosText")}
                        topMargin="0"
                        actionTitle={t("photoGallery.addPhotos")}
                        action={() =>
                          navigate(
                            getRestaurantRoutePrefix(
                              restaurant,
                              "photo-gallery",
                            ),
                          )
                        }
                      />
                    )}
                  </Box>
                </Paper>
                <Paper elevation={3} className="p-8 sm:ml-4 mt-4">
                  <Box>
                    <Grid container>
                      <Grid xs={12} sm={6}>
                        <Typography variant="h6">
                          {t("account.arrivalInstructions")}
                        </Typography>
                      </Grid>
                      <Grid xs={12} sm={5} className="md:text-right">
                        <Typography variant="body2">
                          {restaurant.details.arrivalPolicy}
                        </Typography>
                      </Grid>
                      <Grid xs={0} sm={1} className="md:text-right">
                        <Link
                          to={getRestaurantRoutePrefix(
                            restaurant,
                            "edit#arrival-instructions",
                          )}
                        >
                          <IconButton>
                            <ChevronRightIcon sx={{ fontSize: 24 }} />
                          </IconButton>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
                <Paper elevation={3} className="p-8 sm:ml-4 mt-4">
                  <Box>
                    <Grid container>
                      <Grid xs={12} sm={6}>
                        <Typography variant="h6">
                          {t("account.cancellationPolicy")}
                        </Typography>
                      </Grid>
                      <Grid xs={12} sm={5} className="md:text-right">
                        <Typography variant="body2">
                          {restaurant.details.cancellationPolicy}
                        </Typography>
                      </Grid>
                      <Grid xs={0} sm={1} className="md:text-right">
                        <Link
                          to={getRestaurantRoutePrefix(
                            restaurant,
                            "edit#cancellation-policy",
                          )}
                        >
                          <IconButton>
                            <ChevronRightIcon sx={{ fontSize: 24 }} />
                          </IconButton>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </div>
      ) : (
        <PageSpinner />
      )}
    </div>
  );
}
