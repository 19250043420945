import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import Keycloak, { KeycloakConfig } from "keycloak-js";

import {
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM,
  LocalStorageKeys,
} from "./app/constants";
import { getAuthURL } from "./app/utils/getBackendUrl.util";

const initOptions: KeycloakConfig = {
  url: getAuthURL(),
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
};

export const keycloak = new Keycloak(initOptions);
interface InitKeycloak {
  keycloak: Keycloak;
  auth: boolean;
}

export const logout = (client?: ApolloClient<NormalizedCacheObject>) => {
  localStorage.removeItem(LocalStorageKeys.Token);
  localStorage.removeItem(LocalStorageKeys.RefreshToken);
  client?.resetStore();
  keycloak.logout();
};

export const persistToken = (token: string, refreshToken: string) => {
  localStorage.setItem(LocalStorageKeys.Token, token);
  localStorage.setItem(LocalStorageKeys.RefreshToken, refreshToken);
};

export interface InitKeycloakParams {
  access_token: string;
  expires_in?: string;
  refresh_expires_in?: string;
  refresh_token: string;
  token_type?: string;
  "not-before-policy"?: string;
  session_state?: string;
  scope?: string;
}

keycloak.onTokenExpired = async () => {
  refreshToken();
};

let initialized = false;
export const initKeycloak = async (
  params: InitKeycloakParams,
): Promise<InitKeycloak> => {
  try {
    if (initialized) {
      keycloak.token = params.access_token;
      keycloak.refreshToken = params.refresh_token;
      return { keycloak, auth: keycloak.authenticated! };
    }

    const auth = await keycloak.init({
      responseMode: "fragment",
      token: params.access_token,
      refreshToken: params.refresh_token,
      checkLoginIframe: false,
    });

    initialized = true;
    if (auth) {
      persistToken(keycloak.token!, keycloak.refreshToken!);
    } else {
      logout();
    }
    return { keycloak, auth };
  } catch (error) {
    logout();
  } finally {
    return { keycloak, auth: keycloak.authenticated! };
  }
};

const refreshToken = async () => {
  if (keycloak.authenticated) {
    const seconds = Math.round(
      (keycloak.tokenParsed?.exp ?? 0) - new Date().getTime() / 1000,
    );
    const refreshed = await keycloak.updateToken(seconds);
    if (refreshed) {
      persistToken(keycloak.token!, keycloak.refreshToken!);
    } else {
      logout();
    }
  } else {
    if (!window.location.pathname.startsWith("/login")) {
      window.location.pathname = "/login";
    }
  }
};
