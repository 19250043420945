import { gql } from "@apollo/client";

export const GET_RESTAURANT = gql`
  query Restaurant($uuid: String!) {
    restaurant(uuid: $uuid) {
      uuid
      name
      notes
      seatCount
      cuisine
      details {
        arrivalPolicy
        description
        generalPolicy
        cancellationPolicy
      }
      reservations {
        status
      }
      listed
      city {
        name
        location
        country
        addressLine1
        addressLine2
        zipCode
      }
      contact {
        name
        email
        phone
        website
        instagram
      }
      location {
        longitude
        latitude
      }
      contractDetail {
        nominalDailyReservations
        nominalDailyMaxPartySize
      }
    }
  }
`;

export const GET_RESTAURANTS = gql`
  query Restaurants(
    $city: String!
    $longitude: String
    $latitude: String
    $radiusInKm: Int
    $limit: Int
    $offset: Int
  ) {
    restaurants(
      location: {
        city: $city
        radiusInKm: $radiusInKm
        longitude: $longitude
        latitude: $latitude
      }
      options: { limit: $limit, offset: $offset }
    ) {
      items {
        uuid
        name
        notes
        seatCount
        cuisine
        reservations {
          status
        }
        details {
          arrivalPolicy
          description
          generalPolicy
          cancellationPolicy
        }
        listed
        city {
          name
          location
          country
          addressLine1
          addressLine2
          zipCode
        }
        contact {
          name
          email
          phone
          website
          instagram
        }
        location {
          longitude
          latitude
        }
        contractDetail {
          nominalDailyReservations
          nominalDailyMaxPartySize
        }
      }
      count
    }
  }
`;

export const GET_MENU = gql`
  query Menu($uuid: String!) {
    menu(uuid: $uuid) {
      uuid
      categories {
        uuid
        title
        items {
          uuid
          name
          order
          description
          price {
            amount
            currency
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_MANAGER = gql`
  query Manager {
    manager {
      uuid
      birthDate
      profilePicture
      username
      firstName
      lastName
      preferredName
      email
      phone
      createdAt
      updatedAt
    }
  }
`;

export const GET_RESERVATIONS = gql`
  query Reservations(
    $uuid: String
    $from: String
    $to: String
    $limit: Int
    $offset: Int
  ) {
    reservations(
      uuid: $uuid
      search: { from: $from, to: $to }
      pagination: { limit: $limit, offset: $offset }
    ) {
      items {
        uuid
        table {
          label
          type
          location
          recommendedPartySize
          maxPartySize
          minPartySize
        }
        credit {
          amount
        }
        date {
          start
          end
        }
        status
        member {
          uuid
          membershipId
          allergies
          restrictions
          bio
          joinDate
          birthDate
          profilePicture
          username
          firstName
          lastName
          preferredName
          email
          phone
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const GET_GALLERY = gql`
  query Gallery($uuid: String!) {
    gallery(uuid: $uuid) {
      uuid
      primary {
        uuid
        data
        type
        alt
        createdAt
        updatedAt
      }
      items {
        uuid
        image {
          uuid
          data
          type
          alt
          createdAt
          updatedAt
        }
        order
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const GET_RESY_VENU = gql`
  query ResyVenu($uuid: String!) {
    resyVenue(uuid: $uuid) {
      id
      name
    }
  }
`;

export const GET_RESY_AVAILABLE_VENUES = gql`
  query ResyAvailableVenues($uuid: String!) {
    resyAvailableVenues(uuid: $uuid) {
      id
      name
    }
  }
`;

export const GET_RESY_TAGS = gql`
  query ResyTags($uuid: String!) {
    resyTags(uuid: $uuid) {
      name
    }
  }
`;

export const GET_RESY_AVAILABLE_TAGS = gql`
  query ResyAvailableVenues($uuid: String!) {
    resyAvailableTags(uuid: $uuid) {
      name
    }
  }
`;

export const GET_RESY_STATUS = gql`
  query ResyStatus($uuid: String!) {
    resyStatus(uuid: $uuid)
  }
`;

export const GET_RESY_VALID = gql`
  query ResyValid($uuid: String!) {
    resyValid(uuid: $uuid)
  }
`;

export const GET_MEMBERS = gql`
  query Members($limit: Int, $offset: Int) {
    members(limit: $limit, offset: $offset) {
      items {
        uuid
        membershipId
        allergies
        restrictions
        note
        bio
        joinDate
        birthDate
        profilePicture
        username
        firstName
        lastName
        preferredName
        email
        balance
        active
        phone
        address {
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const GET_MEMBER = gql`
  query Members($uuid: String!) {
    member(uuid: $uuid) {
      uuid
      membershipId
      allergies
      restrictions
      note
      bio
      joinDate
      birthDate
      profilePicture
      username
      firstName
      lastName
      preferredName
      email
      phone
      balance
      active
      address {
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_RESTY_TABLE_TYPES = gql`
  query ResyTableTypes($uuid: String!) {
    resyTableTypes(uuid: $uuid)
  }
`;

export const GET_PRICING_RULE_CATEGORY = gql`
  query PriceRuleCategoryForRestaurant($uuid: String!) {
    priceRuleCategoryForRestaurant(uuid: $uuid) {
      month
      year
    }
  }
`;

export const GET_PRICING_RULE = gql`
  query PriceRuleForRestaurant($uuid: String!, $month: Int, $year: Int) {
    priceRuleForRestaurant(uuid: $uuid, month: $month, year: $year) {
      month
      year
      dayOfWeek
      tableSize
      time
      tableTypes
      credit
      restaurant {
        uuid
      }
      uuid
      createdAt
      updatedAt
    }
  }
`;

export const GET_ACTIVITY_FOR_RESERVATION = gql`
  query ActivityForReservation($uuid: String!, $limit: Int, $offset: Int) {
    activityForReservation(uuid: $uuid, limit: $limit, offset: $offset) {
      items {
        uuid
        type
        success
        description
        createdAt
        reservation {
          credit {
            amount
          }
        }
      }
      count
    }
  }
`;

export const GET_ACTIVITY_FOR_MEMBER = gql`
  query ActivityForMember($uuid: String!, $limit: Int, $offset: Int) {
    activityForMember(uuid: $uuid, limit: $limit, offset: $offset) {
      items {
        uuid
        type
        success
        transaction {
          amount
        }
        description
        createdAt
      }
      count
    }
  }
`;

export const GET_TRANSACTIONS_FOR_MEMBER = gql`
  query TransactionsForMember($uuid: String!, $limit: Int, $offset: Int) {
    transactionsForMember(uuid: $uuid, limit: $limit, offset: $offset) {
      items {
        uuid
        type
        transcript
        createdAt
      }
      count
    }
  }
`;

export const GET_MEMBER_BY_EMAIL = gql`
  query MemberByEmail($email: String!) {
    memberByEmail(email: $email) {
      uuid
      membershipId
      allergies
      restrictions
      note
      bio
      joinDate
      birthDate
      profilePicture
      username
      firstName
      lastName
      preferredName
      email
      phone
      status
      active
      balance
      createdAt
      updatedAt
    }
  }
`;

export const REPORT_RESTAURANT_PERFORMANCE = gql`
  query ReportRestaurantPerformance(
    $uuid: String!
    $from: String
    $to: String
    $limit: Int
    $offset: Int
  ) {
    reportRestaurantPerformance(
      uuid: $uuid
      properties: { from: $from, to: $to, limit: $limit, offset: $offset }
    ) {
      count
      report {
        reservationDate
        totalReservations
        reservedCount
        freeBookedCount
        canceledCount
        releasedCount
        listedCount
        availableCount
        totalMaxPartySize
      }
    }
  }
`;

export const INSPECT_RESTAURANTS = gql`
  query InspectRestaurants($restaurants: [String], $from: String, $to: String) {
    inspectRestaurants(
      input: { restaurants: $restaurants, from: $from, to: $to }
    ) {
      day
      status
      reason
      restaurant {
        uuid
        name
      }
    }
  }
`;
