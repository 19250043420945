export enum DomainEnv {
  local = "local",
  dev = "dev",
  prod = "prod",
  staging = "staging",
  test = "test",
}

export const isManagement = true;
export const getEnv = () => {
  const env = process.env.REACT_APP_ENVIRONMENT;
  return DomainEnv[env as keyof typeof DomainEnv] || DomainEnv.local;
};
