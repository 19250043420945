import moment from "moment";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { modalStyle } from "../../constants";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers";

export default function DateRangePicker({
  save,
  isOpen,
  handleClose,
  defaultName,
}: {
  save: (range: moment.Moment[]) => void;
  isOpen: boolean;
  handleClose: () => void;
  defaultName?: string;
}) {
  const [startDate, setStartDate] = useState<moment.Moment | undefined>(
    moment().subtract(7, "days"),
  );
  const [endDate, setEndDate] = useState<moment.Moment | undefined>(moment());
  const { t } = useTranslation();

  const handleSave = () => {
    save([startDate!, endDate!]);
    handleClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="date-picker-title"
      aria-describedby="date-picker-description"
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isOpen}>
        <Box sx={modalStyle}>
          <div className="flex justify-end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography id="date-picker-title" variant="body1">
            {t("reservations.selectDateRange")}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box className="flex flex-col md:flex-row gap-3 gap-y-3 mt-8 items-center">
              <DatePicker
                maxDate={endDate}
                value={startDate}
                label={t("dateRangePicker.startDate")}
                onChange={(newValue) => setStartDate(newValue!)}
              />
              <Typography className="hidden md:block">—</Typography>
              <DatePicker
                minDate={startDate}
                value={endDate}
                label={t("dateRangePicker.endDate")}
                onChange={(newValue) => setEndDate(newValue!)}
              />
            </Box>
          </LocalizationProvider>
          <div className="flex justify-end gap-2 mt-4">
            <Button onClick={handleClose}>{t("common.cancel")}</Button>
            <Button
              variant="contained"
              type="button"
              color="primary"
              disabled={!startDate || !endDate}
              onClick={handleSave}
            >
              {t("common.save")}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
