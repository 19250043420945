import { isManagement } from "../config";
import { Restaurant } from "../types";

export const getRestaurantRoutePrefix = (
  restaurant?: Restaurant | null,
  path?: string,
) => {
  return isManagement
    ? restaurant?.uuid
      ? `/restaurants/${restaurant.uuid}/${path}`
      : (path ?? "")
    : `/account/profile/${path}`;
};
