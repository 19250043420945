import {
  ChangeEvent,
  MouseEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { t } from "i18next";
import Paper from "@mui/material/Paper";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TableSortLabel,
  Typography,
  Table,
  TablePagination,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Chip,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import SearchInput from "../../components/common/SearchInput";
import RestaurantEdit from "./RestaurantEdit";
import NoData from "../../components/common/NoData";
import { Restaurant } from "@/app/types/Restaurant";
import ConfirmModal from "../../components/common/ConfirmModal";
import { AppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { CountryList, ReservationStatus, Severity } from "../../constants";
import { useMutation, useQuery } from "@apollo/client";
import {
  ADD_RESTAURANT,
  GET_RESTAURANTS,
  UPDATE_RESTAURANT_INFORMATION,
} from "../../graphql";
import { getRestaurantRoutePrefix } from "../../utils/getRestaurantRoutePrefix.util";
import Spinner from "../../components/common/Spinner";
import { getRestaurantPayload } from "../../utils/getRestaurantPayload.util";
import moment from "moment";

interface Column {
  id:
    | keyof Restaurant
    | "name"
    | "address"
    | "cuisine"
    | "seatCount"
    | "reservations"
    | "listed"
    | "actions";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value?: number | string) => string;
}

const columns: readonly Column[] = [
  { id: "name", label: t("restaurants.name"), minWidth: 170 },
  {
    id: "address",
    label: t("restaurants.addressCityState"),
    minWidth: 100,
  },
  {
    id: "cuisine",
    label: t("restaurants.cuisine"),
    minWidth: 100,
  },
  {
    id: "seatCount",
    label: t("restaurants.seatCount"),
    minWidth: 70,
  },
  {
    id: "reservations",
    label: t("restaurants.availableReservations"),
    minWidth: 70,
  },
  {
    id: "listed",
    label: t("restaurants.status"),
    minWidth: 70,
  },
  {
    id: "actions",
    label: "",
    minWidth: 200,
    align: "right",
  },
];

enum Order {
  ASC = "asc",
  DESC = "desc",
}

enum RowFields {
  NAME = "name",
  SEAT_COUNT = "seatCount",
  CUISINE = "cuisine",
}

function descendingComparator(
  a: Restaurant,
  b: Restaurant,
  orderBy: RowFields,
) {
  if (b[orderBy]! < a[orderBy]!) {
    return -1;
  }

  if (b[orderBy]! > a[orderBy]!) {
    return 1;
  }

  return 0;
}

function getComparator(order: Order, orderBy: RowFields) {
  return order === Order.DESC
    ? (a: Restaurant, b: Restaurant) => descendingComparator(a, b, orderBy)
    : (a: Restaurant, b: Restaurant) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: Restaurant[], comparator: Function) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }

    switch (typeof a[1]) {
      case "number":
        return a[1] - Number(b[1]);
      case "string":
        return String(a[1]).localeCompare(b[1].toString());
      default:
        return a[1] < b[1] ? -1 : 1;
    }
  });
  return stabilizedThis.map((el) => el[0]);
}

const getRowValue = (row: Restaurant, column: Column) => {
  const { id: key, format } = column;
  if (format) {
    return format(row[key as keyof Restaurant].toString());
  }

  const value = row[key as keyof Restaurant];
  switch (key) {
    case "address":
      return (
        [row?.city?.name, row?.city?.location].filter(Boolean).join(", ") || "—"
      );
    case "listed":
      return t(`restaurants.listed-${value}`);
    case "reservations":
      return (
        row.reservations?.filter?.(
          (i) => i.status === ReservationStatus.AVAILABLE,
        ).length ?? 0
      );
    case "cuisine":
      return row.cuisine?.join(", ");
    default:
      return value ?? "—";
  }
};
const CITY = "New York";
export default function Restaurants() {
  const [addRestaurant, { loading: isLoadingResult }] =
    useMutation(ADD_RESTAURANT);
  const [updateRestaurantInformation, { loading: isLoadingUpdateResult }] =
    useMutation(UPDATE_RESTAURANT_INFORMATION);

  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState(Order.ASC);
  const [orderBy, setOrderBy] = useState<RowFields | null>(null);
  const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
  const [searchText, setSearchText] = useState("");
  const [restaurant, setRestaurant] = useState<Restaurant | null>(null);
  const [cachedRestaurant, setCachedRestaurant] = useState<Restaurant | null>(
    null,
  );
  const [showDelete, setShowDelete] = useState(false);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [requestErrors, setRequestErrors] = useState<{
    [key: string]: number | string;
  }>({});
  const {
    setRestaurant: setAppContextRestaurant,
    setCategories,
    setGallery,
    setSnackBarMessageProps,
  } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: restaurantsData,
    loading,
    refetch,
  } = useQuery<{
    restaurants: { items: Restaurant[]; count: number };
  }>(GET_RESTAURANTS, {
    variables: { limit: rowsPerPage, offset: 0, city: CITY },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (restaurantsData?.restaurants.items) {
      setRestaurants(restaurantsData.restaurants.items);
    }
  }, [restaurantsData?.restaurants]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const handleRefetch = async (limit: number, offset: number) => {
    try {
      setIsLoading(true);
      await refetch({
        limit,
        offset,
        city: CITY,
      });
    } catch (error) {
      setSnackBarMessageProps?.({
        message: t("common.error"),
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    try {
      await handleRefetch(rowsPerPage, newPage * rowsPerPage);
      setPage(newPage);
    } catch {}
  };

  const handleShowDetail = async (
    e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>,
    row: Restaurant,
  ) => {
    e.stopPropagation();
    await Promise.all([setCategories!(undefined), setGallery!(undefined)]);
    setAppContextRestaurant!(row);
    navigate(getRestaurantRoutePrefix(row, ""));
  };

  const handleCreateResturant = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    handleShowEdit(
      e,
      cachedRestaurant ||
        ({
          city: { country: CountryList.US },
          manager: { birthdate: moment().subtract(18, "years").toDate() },
        } as any),
    );
  };

  const handleSaveRestaurant = async (payload: Restaurant) => {
    const variables = getRestaurantPayload(payload);
    setRequestErrors({});
    if (payload.uuid) {
      try {
        const { errors, data } = await updateRestaurantInformation({
          variables,
        });
        if (errors) {
          throw errors;
        }
        const updatedRestaurant = data.updateRestaurantInformation;
        setRestaurants((restaurants) =>
          restaurants.map((r) =>
            r.uuid === updatedRestaurant.uuid ? updatedRestaurant : r,
          ),
        );
        setIsDetailOpen(false);
        setSnackBarMessageProps?.({
          message: t("common.success"),
          severity: Severity.Success,
        });
        setCachedRestaurant(null);
      } catch (error) {
        console.error(error);
        setSnackBarMessageProps?.({
          message: t("common.error", {
            error: (error as Error).message,
          }),
          severity: Severity.Error,
        });

        return error;
      }
    } else {
      try {
        const { errors, data } = await addRestaurant({
          variables,
        });
        if (errors) {
          throw errors;
        }
        const newRestaurant = data.addRestaurant;
        setRestaurants([...restaurants, newRestaurant]);
        setIsDetailOpen(false);
        setSnackBarMessageProps?.({
          message: t("common.success"),
          severity: Severity.Success,
        });
      } catch (error) {
        const e = error as any;
        console.error({ error });
        const statusCode =
          e?.cause?.result?.errors?.[0]?.message?.indexOf?.(409) > -1
            ? 409
            : e?.statusCode;
        switch (statusCode) {
          case 409:
            setRequestErrors({ managerEmail: "duplicate" });
            setSnackBarMessageProps?.({
              message: t("restaurants.duplicateManager"),
              severity: Severity.Error,
            });
            break;
          default: {
            setSnackBarMessageProps?.({
              message: t("common.error", {
                error: (error as Error).message,
              }),
              severity: Severity.Error,
            });
          }
        }

        return error;
      }
    }
  };

  const handleShowEdit = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    row: Restaurant,
  ) => {
    e.stopPropagation();
    setRestaurant(row);
    setIsDetailOpen(true);
  };

  const handleShowDelete = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    row: Restaurant,
  ) => {
    e.stopPropagation();
    setRestaurant(row);
    setShowDelete(true);
  };

  const handleDeleteRestaurant = () => {
    if (restaurant) {
      const newRestaurants = restaurants.filter(
        (r) => r.uuid !== restaurant.uuid,
      );
      setRestaurants(newRestaurants);
      setShowDelete(false);
    }
  };

  const handleChangeRowsPerPage = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    try {
      const newOffset = +event.target.value;
      handleRefetch(newOffset, 0);
      setRowsPerPage(newOffset);
      setPage(0);
    } catch {}
  };

  const createSortHandler =
    (property: RowFields) => (event: MouseEvent<HTMLAnchorElement>) => {
      const isAsc = orderBy === property && order === Order.ASC;
      setOrder(isAsc ? Order.DESC : Order.ASC);
      setOrderBy(property);
    };

  const visibleRows = (
    useMemo(
      () => stableSort(restaurants, getComparator(order, orderBy!)),
      [restaurants, order, orderBy],
    ) as Restaurant[]
  ).filter((row) => {
    return row.name.toLowerCase().includes(searchText?.toLowerCase() ?? "");
  });

  const tableContent = (
    <Paper>
      <TableContainer sx={{ maxHeight: "60vh" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sortDirection={orderBy === column.id ? order : false}
                  style={{ minWidth: column.minWidth }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : Order.ASC}
                    onClick={createSortHandler(column.id as RowFields)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === Order.DESC
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {visibleRows.map((row, i) => {
              return (
                <TableRow
                  hover
                  role="button"
                  tabIndex={-1}
                  key={i}
                  onClick={(e) => handleShowDetail(e, row)}
                >
                  {columns.map((column) => {
                    switch (column.id) {
                      case "actions":
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <IconButton
                              onClick={(e) => handleShowEdit(e, row)}
                              arial-label={t("common.edit")}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              sx={{ display: "none" }}
                              onClick={(e) => handleShowDelete(e, row)}
                              arial-label={t("restaurant.delete")}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        );
                      default:
                        const value = getRowValue(row, column);
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "listed" ? (
                              <Chip
                                label={t(`restaurants.listed-${row.listed}`)}
                                color={row.listed ? "success" : "error"}
                              ></Chip>
                            ) : (
                              value?.toString()
                            )}
                          </TableCell>
                        );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {visibleRows.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={restaurantsData?.restaurants.count ?? 0}
          disabled={isLoading}
          rowsPerPage={rowsPerPage}
          page={page}
          translate="yes"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );

  const content = loading ? (
    <Spinner />
  ) : (
    visibleRows.length === 0 && (
      <NoData
        topMargin="2rem"
        title={t(`restaurants.${searchText ? "noSearchTitle" : "noDataTitle"}`)}
      />
    )
  );
  return (
    <div>
      <Container maxWidth="xl">
        <div className="flex justify-end my-8">
          <Button onClick={handleCreateResturant} variant="contained">
            <AddIcon /> {t("restaurants.create")}
          </Button>
        </div>
        <Grid container>
          <Grid item xs={12} md={9} sx={{ my: 1 }}>
            <Typography variant="h6" noWrap>
              {t("restaurants.title")}
              <Chip
                label={restaurantsData?.restaurants.count ?? "—"}
                color="primary"
                sx={{ ml: 1 }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sx={{ my: { xs: 2 } }}>
            <SearchInput
              placeholder={t("restaurants.search")}
              change={setSearchText}
            ></SearchInput>
          </Grid>
        </Grid>
        {showDelete && restaurant && (
          <ConfirmModal
            inputIsOpen={showDelete}
            title={t("restaurants.delete")}
            actionTitle={t("common.delete")}
            message={t("restaurants.deleteText")}
            inputHandleClose={() => setShowDelete(false)}
            handleConfirm={handleDeleteRestaurant}
          />
        )}
        {isDetailOpen && restaurant && (
          <RestaurantEdit
            requestErrors={requestErrors}
            isOpen={isDetailOpen}
            restaurant={restaurant}
            setIsOpen={setIsDetailOpen}
            setCachedRestaurant={setCachedRestaurant}
            isLoading={isLoadingResult || isLoadingUpdateResult}
            submit={handleSaveRestaurant}
          />
        )}

        {tableContent}
        {content}
      </Container>
    </div>
  );
}
