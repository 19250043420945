import { useTranslation } from "react-i18next";
import moment from "moment";
import i18next from "i18next";
import { useEffect, useRef, useState } from "react";
import Keycloak from "keycloak-js";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Alert,
  Button,
  CircularProgress,
  Paper,
  Snackbar,
  useMediaQuery,
} from "@mui/material";

import {
  AppContext,
  SnackBarMessageProps,
  User,
} from "../../context/AppContext";
import logoLight from "../../../assets/images/logo-light.svg";
import logoDark from "../../../assets/images/logo-dark.svg";
import { LocalStorageKeys, UIMode } from "../../constants";
import { Gallery, Member, MenuCategory, Restaurant } from "../../types";
import { initKeycloak } from "../../../keycloak";
import { ApplicationRouter } from "./ApplicationRouter";
import { AppData } from "./AppData";

export default function AppContainer() {
  const { t } = useTranslation();
  const didInit = useRef(false);
  const [snackBarMessageProps, setSnackBarMessageProps] =
    useState<SnackBarMessageProps>();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [currentUser, setCurrentUser] = useState<User | undefined>();
  const [member, setMember] = useState<Member | undefined>();
  const [members, setMembers] = useState<Member[] | undefined>([]);
  const [restaurant, setRestaurant] = useState<Restaurant | undefined>();
  const [gallery, setGallery] = useState<Gallery | undefined>();
  const [isGlobalLoading, setIsGlobalLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<MenuCategory[] | undefined>();
  const [resyValid, setResyValid] = useState<boolean>(false);
  const [resyTableTypes, setResyTableTypes] = useState<string[]>([]);
  const keycloak = useRef<Keycloak | undefined>();
  const authInitializeError = useRef<Error | undefined>();

  let currentUiMode = prefersDarkMode ? UIMode.Dark : UIMode.Light;
  const [uiMode, setUiMode] = useState(currentUiMode);
  moment.locale(i18next.language);

  const initAuth = async () => {
    const token = localStorage.getItem(LocalStorageKeys.Token);
    const refreshToken = localStorage.getItem(LocalStorageKeys.RefreshToken);
    if (!token || !refreshToken) {
      // window.location.href = "/login";
      return;
    }

    const { keycloak } = await initKeycloak({
      access_token: token,
      refresh_token: refreshToken,
    });

    setCurrentUser!(keycloak.tokenParsed! as User);
    return keycloak;
  };

  useEffect(() => {
    if (currentUiMode !== uiMode) {
      setUiMode(currentUiMode);
    }

    if (!didInit.current) {
      initAuth().then((kc) => {
        keycloak.current = kc;
      });
      didInit.current = true;
    }
  }, [currentUiMode, uiMode]);

  const theme = createTheme({
    typography: {
      fontFamily: "Enduro",
      button: {
        textTransform: "none",
        backgroundColor: "none",
      },
    },
    palette: {
      primary: {
        main: uiMode === UIMode.Dark ? "#fff" : "#000",
        light: uiMode === UIMode.Dark ? "#000" : "#fff",
        dark: uiMode === UIMode.Dark ? "#a6a6a6" : "#6a6a6a",
      },
      action: {
        active: uiMode === UIMode.Dark ? "#fff" : "#000",
      },
      background: {
        default: uiMode === UIMode.Dark ? "#000" : "#F9FAFB",
        paper: uiMode === UIMode.Dark ? "#111" : "#fff",
      },
      secondary: {
        main: uiMode === UIMode.Dark ? "#a6a6a6" : "#6a6a6a",
        light: uiMode === UIMode.Dark ? "#6a6a6a" : "#a6a6a6",
        dark: uiMode === UIMode.Dark ? "#6a6a6a" : "#a6a6a6",
      },
      mode: uiMode,
    },
  });

  const authInitialized = keycloak.current?.authenticated ?? true;
  const logo = uiMode === UIMode.Dark ? logoDark : logoLight;
  const uuid =
    window.location.pathname.startsWith("/restaurants/") &&
    window.location.pathname.split(/\//)[2];
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {authInitialized ? (
        <AppContext.Provider
          value={{
            uiMode,
            currentUser,
            setCurrentUser,
            setRestaurant,
            restaurant,
            member,
            setUiMode,
            setMember,
            members,
            setMembers,
            setResyValid,
            resyValid,
            setCategories,
            categories,
            setGallery,
            resyTableTypes,
            setResyTableTypes,
            isGlobalLoading,
            setIsGlobalLoading,
            gallery,
            setSnackBarMessageProps,
          }}
        >
          <main>
            <ApplicationRouter />
            {keycloak.current?.authenticated && uuid && <AppData uuid={uuid} />}
            <Snackbar
              open={!!snackBarMessageProps}
              autoHideDuration={6000}
              onClose={() => setSnackBarMessageProps(undefined)}
            >
              <Alert
                severity={snackBarMessageProps?.severity}
                variant="filled"
                action={
                  snackBarMessageProps?.action === "resy" && (
                    <Button
                      size="small"
                      aria-label="close"
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        (window.location.href = `/restaurants/${uuid}/connect-platform/resy`)
                      }
                    >
                      {t("platform.resy.connectResy")}
                    </Button>
                  )
                }
                sx={{ width: "100%" }}
              >
                {snackBarMessageProps?.message}
              </Alert>
            </Snackbar>
          </main>
        </AppContext.Provider>
      ) : authInitializeError.current ? (
        <Paper elevation={3} className="p-8 m-[10%_auto] w-[40%] text-center">
          <p>{t("auth.authError")}</p>
          <p>{authInitializeError.current?.message}</p>
        </Paper>
      ) : (
        <div className="m-[20%_auto] w-[100%] text-center">
          <img
            src={logo}
            alt="ACCESS"
            width={120}
            className="m-[10px_auto_40px]"
          />
          <CircularProgress color="inherit" aria-label={t("common.loading")} />
        </div>
      )}
    </ThemeProvider>
  );
}
