import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  Chip,
  Container,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import AccountHeader from "./AccountHeader";
import { RestaurantPlatforms, Severity } from "../../constants";
import { AppContext } from "../../context/AppContext";
import {
  GET_RESY_AVAILABLE_VENUES,
  GET_RESY_STATUS,
  GET_RESY_TAGS,
  GET_RESY_VALID,
  GET_RESY_VENU,
  GET_RESY_AVAILABLE_TAGS,
  ACTIVATE_RESY,
  SET_RESY_CREDENTIALS,
  SET_RESY_TAGS,
  SET_RESY_VENUE,
  GET_RESTY_TABLE_TYPES,
} from "../../graphql";
import { Info } from "@mui/icons-material";

export default function PlatformPage() {
  const { platform } = useParams();
  return (
    <div>
      <AccountHeader />
      <Container maxWidth="xl">
        {platform === RestaurantPlatforms.Resy && <ResyConfiguration />}
      </Container>
    </div>
  );
}

interface ResyValue {
  name: string;
  id: string;
}

function ResyConfiguration() {
  const { setSnackBarMessageProps } = useContext(AppContext);
  const { id: uuid } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resyVenue, setResyVenue] = useState<{
    name: string;
    id: string;
  } | null>(null);
  const [resyTags, setResyTags] = useState<ResyValue[]>([]);
  const [isActive, setIsActive] = useState(false);
  const { data: resyTagsData } = useQuery<{ resyTags: ResyValue[] }>(
    GET_RESY_TAGS,
    {
      variables: { uuid },
    },
  );

  const { data: resyVenueData } = useQuery<{
    resyVenue: ResyValue;
  }>(GET_RESY_VENU, {
    variables: { uuid },
  });
  const { data: resyVenuesData, refetch: getResyAvailableVenues } = useQuery<{
    resyAvailableVenues: ResyValue[];
  }>(GET_RESY_AVAILABLE_VENUES, {
    variables: { uuid },
  });

  const initializeResy = async () => {
    await Promise.all([
      getResyValid({ uuid }),
      getResyStatus({ uuid }),
      handleActivateResy(true),
    ]);

    const {
      data: { resyAvailableVenues },
    } = await getResyAvailableVenues({ uuid });

    if (resyAvailableVenues[0]) {
      return handleUpateVenue(resyAvailableVenues[0]);
    }
  };
  const { data: resyAvailableTagsData, refetch: getResyAvailableTags } =
    useQuery<{
      resyAvailableTags: ResyValue[];
    }>(GET_RESY_AVAILABLE_TAGS, {
      variables: { uuid },
    });

  const [getResyTables] = useLazyQuery(GET_RESTY_TABLE_TYPES, {
    variables: { uuid },
  });

  const {
    data: resyStatusData,
    loading: resyStatusLoading,
    refetch: getResyStatus,
  } = useQuery<{
    resyStatus: boolean;
  }>(GET_RESY_STATUS, {
    variables: { uuid },
  });
  const {
    data: resyValidData,
    loading: resyValidLoading,
    refetch: getResyValid,
  } = useQuery<{
    resyValid: boolean;
  }>(GET_RESY_VALID, {
    variables: { uuid },
  });
  const [setResyCredentials, { loading: credentialsLoading }] =
    useMutation(SET_RESY_CREDENTIALS);

  const [updateResyVenu, { loading: venueLoading }] =
    useMutation(SET_RESY_VENUE);

  const [updateResyTags, { loading: tagsLoading }] = useMutation(SET_RESY_TAGS);

  const [activateResy, { loading: activateLoading }] =
    useMutation(ACTIVATE_RESY);

  const isLoading =
    credentialsLoading ||
    resyStatusLoading ||
    venueLoading ||
    tagsLoading ||
    activateLoading ||
    resyValidLoading;
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const { data } = await setResyCredentials({
        variables: {
          uuid,
          email,
          password,
        },
      });
      if (data.setResyCredentials) {
        setSnackBarMessageProps?.({
          message: t("common.success"),
          severity: Severity.Success,
        });
        initializeResy();
      } else {
        setSnackBarMessageProps?.({
          message: t("auth.invalidCredentials"),
          severity: Severity.Error,
        });
      }
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("common.error", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });
    }
  };

  const handleUpateVenue = async (variables: ResyValue) => {
    try {
      await updateResyVenu({
        variables: { ...variables, uuid },
      });
      setResyVenue(variables);
      getResyAvailableTags({ uuid });
      getResyTables({ fetchPolicy: "network-only", variables: { uuid } });
      setResyTags([]);
      handleUpateTags([]);
      setSnackBarMessageProps?.({
        message: t("common.success"),
        severity: Severity.Success,
      });
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("common.error", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });
    }
  };

  const handleActivateResy = async (active: boolean) => {
    try {
      await activateResy({
        variables: { active, uuid },
      });
      setIsActive(active);
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("common.error", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });
    }
  };

  const handleUpateTags = async (tags: ResyValue[]) => {
    try {
      await updateResyTags({
        variables: { tags: tags.map((tag) => tag.name), uuid },
      });
      setResyTags(tags);
      setSnackBarMessageProps?.({
        message: t("common.success"),
        severity: Severity.Success,
      });
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("common.error", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });
    }
  };

  useEffect(() => {
    if (resyVenueData?.resyVenue) {
      setResyVenue(resyVenueData.resyVenue);
    }
  }, [resyVenueData]);

  useEffect(() => {
    if (resyTagsData?.resyTags) {
      setResyTags(resyTagsData.resyTags);
    }
  }, [resyTagsData]);

  useEffect(() => {
    if (resyStatusData?.resyStatus) {
      setIsActive(resyStatusData.resyStatus);
    }
  }, [resyStatusData]);

  const resyVenues = resyVenuesData?.resyAvailableVenues ?? [];
  const resyValid = resyValidData?.resyValid ?? false;
  const resyAvailableTags = resyAvailableTagsData?.resyAvailableTags ?? [];

  if (resyValidLoading) {
    return (
      <div className="mt-8">
        <Skeleton
          variant="rounded"
          animation="wave"
          height={120}
          sx={{ mb: 3 }}
        />
        <Skeleton
          variant="rounded"
          animation="wave"
          height={120}
          sx={{ mb: 3 }}
        />
        <Skeleton
          variant="rounded"
          animation="wave"
          height={120}
          sx={{ mb: 3 }}
        />
        <Skeleton
          variant="rounded"
          animation="wave"
          height={120}
          sx={{ mb: 3 }}
        />
        <Skeleton
          variant="rounded"
          animation="wave"
          height={120}
          sx={{ mb: 3 }}
        />
      </div>
    );
  }
  return (
    <div className="mt-10">
      <Typography variant="h5" gutterBottom>
        {t("platform.resy.title")}{" "}
        <Chip
          variant={isActive ? "filled" : "outlined"}
          sx={{ marginRight: 1 }}
          color={isActive ? "success" : "error"}
          label={t(`platform.resy.status-${isActive ? "active" : "inactive"}`)}
        />
      </Typography>
      {!resyValid && !resyValidLoading ? (
        <Card sx={{ p: 4, my: 2 }}>
          <form className="gap-4" onSubmit={handleSubmit}>
            <Typography variant="h6" gutterBottom className="flex-1">
              {t("platform.resy.connectResy")}
            </Typography>
            <TextField
              id="email"
              value={email}
              disabled={isLoading}
              onChange={(e) => setEmail(e.target.value)}
              label={t("auth.email")}
              required={true}
              variant="outlined"
              fullWidth
              placeholder="email@example.com"
              margin="normal"
            />

            <TextField
              id="password"
              label={t("auth.password")}
              variant="outlined"
              type="password"
              disabled={isLoading}
              required={true}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              placeholder="*************"
              margin="normal"
            />

            <p className="mt-8 text-right">
              <Button
                variant="contained"
                type="submit"
                disabled={isLoading}
                color="primary"
                sx={{ width: "100%" }}
              >
                {t("platform.resy.connect")}
              </Button>
            </p>
          </form>
        </Card>
      ) : (
        <div>
          <Card sx={{ p: 4, my: 2 }}>
            <div className="flex gap-4">
              <Typography variant="h6" gutterBottom className="flex-1">
                {t("platform.resy.status")}
              </Typography>
              <div>
                <FormGroup>
                  <FormControlLabel
                    color={isActive ? "primary" : "error"}
                    control={
                      <Switch
                        disabled={!resyValid || isLoading}
                        checked={isActive}
                        color={isActive ? "primary" : "error"}
                        onChange={(_, newValue) => handleActivateResy(newValue)}
                      />
                    }
                    label={t(
                      `platform.resy.status-${isActive ? "active" : "inactive"}`,
                    )}
                  />
                </FormGroup>
              </div>
            </div>
          </Card>
          <Card sx={{ p: 4, my: 2 }}>
            <div className="flex gap-4">
              <Typography variant="h6" gutterBottom className="flex-1">
                {t("platform.resy.venue")}
              </Typography>
              <div className="flex-1">
                <Autocomplete
                  options={resyVenues}
                  disabled={!resyValid || isLoading}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={resyVenue}
                  onChange={(_, newValue) => handleUpateVenue(newValue!)}
                  filterSelectedOptions
                  renderInput={(params) => <TextField {...params} />}
                />
                <Alert color="warning" icon={<Info />} sx={{ mt: 1 }}>
                  {t("platform.venueChangeNotice")}
                </Alert>
              </div>
            </div>
          </Card>
          <Card sx={{ p: 4, my: 2 }}>
            <div className="flex gap-4">
              <Typography variant="h6" gutterBottom className="flex-1">
                {t("platform.resy.tags")}
              </Typography>
              <div className="flex-1">
                <Autocomplete
                  multiple
                  disabled={!resyValid || isLoading}
                  options={resyAvailableTags}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  onChange={(_, newValue) => handleUpateTags(newValue)}
                  getOptionLabel={(option) => option.name}
                  value={resyTags}
                  filterSelectedOptions
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </div>
          </Card>

          <Card sx={{ p: 4, my: 2 }}>
            <div className="flex gap-4">
              <Typography variant="h6" gutterBottom className="flex-1">
                {t("platform.resy.valid")}
              </Typography>
              <div>
                <Chip
                  variant={resyValid ? "filled" : "outlined"}
                  sx={{ marginRight: 1 }}
                  color={resyValid ? "success" : "error"}
                  label={t(`platform.resy.valid-${resyValid}`)}
                />
              </div>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
}
