import {
  Box,
  Button,
  FormControl,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";

import profileIcon from "../../../assets/images/getting-started-profile.svg";
import profileActiveIcon from "../../../assets/images/getting-started-profile-active.svg";
import menuIcon from "../../../assets/images/getting-started-menu.svg";
import resyIcon from "../../../assets/images/getting-started-resy.svg";
import photosIcon from "../../../assets/images/getting-started-photos.svg";
import doneIcon from "../../../assets/images/getting-started-done.svg";
import menuActiveIcon from "../../../assets/images/getting-started-menu-active.svg";
import resyActiveIcon from "../../../assets/images/getting-started-resy-active.svg";
import photosActiveIcon from "../../../assets/images/getting-started-photos-active.svg";

import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Restaurant } from "@/app/types/Restaurant";
import { RestaurantPlatforms } from "../../constants";
import { useState } from "react";
import { getRestaurantRoutePrefix } from "../../utils/getRestaurantRoutePrefix.util";

export const setupItems = [
  {
    id: "addPhotos",
    icon: photosIcon,
    iconActive: photosActiveIcon,
    to: getRestaurantRoutePrefix(null, "photo-gallery"),
  },
  {
    id: "setupMenu",
    icon: menuIcon,
    iconActive: menuActiveIcon,
    to: getRestaurantRoutePrefix(null, "menu"),
  },
  {
    id: "arrivalInstructions",
    icon: profileIcon,
    iconActive: profileActiveIcon,
    to: getRestaurantRoutePrefix(null, "edit#arrival-instructions"),
  },
  {
    id: "cancellationPolicy",
    icon: profileIcon,
    iconActive: profileIcon,
    to: getRestaurantRoutePrefix(null, "edit#cancellation-policy"),
  },
  {
    id: "connectPlatform",
    icon: resyIcon,
    iconActive: resyActiveIcon,
    action: (param: string, _: NavigateFunction) => {
      const path = getRestaurantRoutePrefix(null, `connect-platform/${param}`);
      window.location.href = path;
    },
  },
];

const SetupStage = function ({
  icon,
  stepId,
  action,
  isPastStep,
  to,
}: {
  icon: string;
  stepId: string;
  action?: (param: string, navigate: NavigateFunction) => void;
  isPastStep?: boolean;
  isCurrentStep?: boolean;
  to?: string;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [restaurantPlatform, setRestaurantPlatform] = useState(
    RestaurantPlatforms.Resy,
  );
  return (
    <Paper sx={{ mt: 2, p: 2, borderRadius: 2 }} elevation={3}>
      <div className="flex flex-col md:flex-row gap-2 items-center py-2">
        <div className="pr-4 pl-2">
          <img
            src={isPastStep ? doneIcon : icon}
            width={56}
            alt={t(`home.getStarted-${stepId}`)}
            style={{ fill: "red", stroke: "red", color: "red" }}
          />
        </div>
        <div className="flex-1">
          <Typography variant="h6">{t(`home.getStarted-${stepId}`)}</Typography>
          <Typography variant="body2">
            {t(`home.getStartedDescription-${stepId}`)}
          </Typography>
          {stepId === "connectPlatform" && (
            <div className="mt-2 pr-4">
              <FormControl fullWidth>
                <Select
                  value={restaurantPlatform}
                  onChange={(e) =>
                    setRestaurantPlatform(e.target.value as RestaurantPlatforms)
                  }
                >
                  {Object.values(RestaurantPlatforms).map((i) => (
                    <MenuItem
                      value={i}
                      key={i}
                      disabled={i !== RestaurantPlatforms.Resy}
                    >
                      {t(`restaurantPlatforms.platform-${i}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </div>

        <div className="md:pr-4 text-left md:my-0 my-4">
          <Link to={to ? to : "#"}>
            <Button
              variant="contained"
              onClick={() => action?.(restaurantPlatform, navigate)}
            >
              {t(`home.getStartedAction-${stepId}`)}
            </Button>
          </Link>
        </div>
      </div>
    </Paper>
  );
};

export default function GettingStarted({
  currentStepIndex = 0,
}: {
  restaurant?: Restaurant;
  currentStepIndex: number;
}) {
  const progress = (currentStepIndex * 100) / setupItems.length;
  return (
    <Box>
      <Box pb={2}>
        <div className="flex gap-2 items-center">
          <div className="flex-1">
            <LinearProgress variant="determinate" value={progress} />
          </div>
          <Typography>{progress}%</Typography>
        </div>
      </Box>
      {setupItems.map(({ id, icon, iconActive, action, to }, index) => (
        <SetupStage
          key={id}
          action={action}
          to={to}
          icon={index === currentStepIndex ? iconActive : icon}
          stepId={id}
          isCurrentStep={index === currentStepIndex}
          isPastStep={index < currentStepIndex}
        />
      ))}
    </Box>
  );
}
