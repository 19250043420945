import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { MouseEventHandler } from "react";

export default function SearchInput({
  placeholder,
  change,
  onClick,
}: {
  placeholder: string;
  change?: (value: string) => void;
  onClick?: MouseEventHandler | undefined;
}) {
  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <IconButton sx={{ p: "10px" }} aria-label="menu" onClick={onClick}>
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        onKeyUp={(e) => {
          if (change) {
            change(e.currentTarget.value);
          }
        }}
        inputProps={{ "aria-label": placeholder }}
      />
    </Paper>
  );
}
