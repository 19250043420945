import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useContext,
  useState,
} from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import {
  Button,
  Container,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
  IconButton,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import moment from "moment";

import { useTranslation } from "react-i18next";

import { Member, MemberInput, MemberInviteInput } from "@/app/types";
import { Close } from "@mui/icons-material";
import {
  Allergies,
  CountryList,
  Memberships,
  Restrictions,
  Severity,
} from "../../constants";
import { INVITE_MEMBER, UPDATE_MEMBER_INFORMATION } from "../../graphql";
import { AppContext } from "../../context/AppContext";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { getDateValue } from "../../utils/getDateValue.util";

export default function MemberDetails({
  isOpen,
  setIsOpen,
  setCachedMember,
  member,
}: {
  isOpen: boolean;
  setCachedMember?: Dispatch<SetStateAction<Member | null>>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  member: Member;
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [updatedMember, setUpdatedMember] = useState<Member>(member);
  const { setMember, setMembers, setSnackBarMessageProps } =
    useContext(AppContext);
  const [updateMemberInformation] = useMutation(UPDATE_MEMBER_INFORMATION);
  const [inviteMember] = useMutation(INVITE_MEMBER);
  const toggleDrawer = (newOpen: SetStateAction<boolean>) => () => {
    setIsOpen(newOpen);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      if (member.uuid) {
        const variables: MemberInput = {
          uuid: member.uuid,
          allergies: updatedMember.allergies,
          firstName: updatedMember.firstName,
          lastName: updatedMember.lastName,
          restrictions: updatedMember.restrictions,
          bio: updatedMember.bio,
          birthdate: updatedMember.birthDate,
          note: updatedMember.note,
          addressLine1: updatedMember.address?.addressLine1,
          addressLine2: updatedMember.address?.addressLine2,
          city: updatedMember.address?.city,
          state: updatedMember.address?.state,
          zipcode: updatedMember.address?.zipcode,
          country: updatedMember.address?.country,
        };
        const { data } = await updateMemberInformation({
          variables,
        });
        if (data?.updateMemberInformation) {
          const memberResponse: Member = {
            ...data.updateMemberInformation,
            balance: updatedMember.balance,
            birthDate: updatedMember.birthDate,
          };
          setMember?.(memberResponse);
          setMembers?.((prev) =>
            prev?.map?.((m) =>
              m.uuid === data.updateMemberInformation?.uuid
                ? memberResponse
                : m,
            ),
          );
        }

        setSnackBarMessageProps?.({
          message: t("common.success"),
          severity: Severity.Success,
        });

        setIsOpen(false);
      } else {
        const variables: MemberInviteInput = {
          firstName: updatedMember.firstName,
          lastName: updatedMember.lastName,
          membershipTier: updatedMember.membership,
          addressLine1: updatedMember.address?.addressLine1,
          addressLine2: updatedMember.address?.addressLine2,
          birthdate: updatedMember.birthDate,
          city: updatedMember.address?.city,
          state: updatedMember.address?.state,
          zip: updatedMember.address?.zipcode,
          country: updatedMember.address?.country,
          phone: updatedMember.phone,
          email: updatedMember.email,
        };
        const { data } = await inviteMember({
          variables,
        });
        if (data?.inviteMember) {
          setSnackBarMessageProps?.({
            message: t("common.success"),
            severity: Severity.Success,
          });
          setIsOpen(false);
        } else {
          setSnackBarMessageProps?.({
            message: t("common.error"),
            severity: Severity.Error,
          });
        }
      }
    } catch (e) {
      console.error(e);
      setSnackBarMessageProps?.({
        message: t("common.error"),
        severity: Severity.Error,
      });
    }
    setIsLoading(false);
  };

  const setField = (
    field: string,
    value: string | string[] | number | undefined,
  ) => {
    const [parent, child] = field.split(".");
    let payload = { ...updatedMember, [field]: value };
    if (child) {
      payload = {
        ...updatedMember,
        [parent]: {
          ...(updatedMember[parent as keyof Member] as any),
          [child]: value,
        },
      };
    }

    setUpdatedMember(payload);
    setCachedMember?.(payload);
  };

  const width = window.innerWidth < 650 ? window.innerWidth - 20 : 650;
  const DrawerContent = (
    <Box sx={{ width }} role="presentation">
      <Container sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid xs={12} textAlign="right">
            <IconButton onClick={toggleDrawer(false)} disabled={isLoading}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Typography variant="h5">
          {t(member.uuid ? "members.update" : "members.create")}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box>
            <Typography variant="h6" mt={4}>
              {t("members.personalInfo")}
            </Typography>
            <Box className="flex md:flex-row flex-col gap-4" mt={1}>
              <Box className="flex-1">
                <TextField
                  id="firstName"
                  value={updatedMember.firstName}
                  onChange={(e) => setField("firstName", e.target.value)}
                  label={t("members.firstname")}
                  required
                  variant="outlined"
                  fullWidth
                  placeholder="John"
                  margin="normal"
                />
              </Box>
              <Box className="flex-1">
                <TextField
                  id="lastname"
                  value={updatedMember.lastName}
                  onChange={(e) => setField("lastName", e.target.value)}
                  label={t("members.lastname")}
                  required
                  variant="outlined"
                  fullWidth
                  placeholder="Doe"
                  margin="normal"
                />
              </Box>
            </Box>
            <Box className="flex md:flex-row flex-col gap-4">
              <Box className="flex-1">
                <TextField
                  id="preferredName"
                  value={updatedMember.preferredName}
                  onChange={(e) => setField("preferredName", e.target.value)}
                  label={t("members.preferredName")}
                  required
                  variant="outlined"
                  fullWidth
                  placeholder="John Doe"
                  margin="normal"
                />
              </Box>
              <Box className="flex-1 pt-4">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    value={moment(getDateValue(updatedMember.birthDate))}
                    onChange={(value) =>
                      setField("birthDate", value?.format("YYYY-MM-DD"))
                    }
                    label={t("members.birthDate")}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <div className="flex md:flex-row flex-col gap-4">
              <Box flex="1">
                <TextField
                  id="phone"
                  value={updatedMember.phone}
                  onChange={(e) => setField("phone", e.target.value)}
                  label={t("members.phone")}
                  required
                  type="tel"
                  variant="outlined"
                  fullWidth
                  placeholder="123-456-7890"
                  margin="normal"
                />
              </Box>
              <Box flex="1">
                <TextField
                  id="email"
                  value={updatedMember.email}
                  onChange={(e) => setField("email", e.target.value)}
                  label={t("members.email")}
                  required
                  type="email"
                  variant="outlined"
                  fullWidth
                  placeholder="email@example.com"
                  margin="normal"
                />
              </Box>
            </div>

            <Box>
              <TextField
                id="note"
                value={updatedMember.note}
                onChange={(e) => setField("note", e.target.value)}
                label={t("members.note")}
                required
                multiline
                minRows={2}
                type="tel"
                variant="outlined"
                fullWidth
                margin="normal"
              />
            </Box>
          </Box>
          {member.uuid && (
            <Box mt={4}>
              <Typography variant="h6">{t("members.dietInfo")}</Typography>
              <Box>
                <Box mt={1}>
                  <Autocomplete
                    multiple
                    options={Object.values(Restrictions).map((i) =>
                      t(`members.restrictionsList.${i}`),
                    )}
                    filterSelectedOptions
                    value={updatedMember.restrictions}
                    onChange={(e, value) => setField("restrictions", value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t(`members.restrictions`)}
                      />
                    )}
                  />
                </Box>
                <Box mt={1}>
                  <Autocomplete
                    multiple
                    options={Object.values(Allergies).map((i) =>
                      t(`members.allergyList.${i}`),
                    )}
                    filterSelectedOptions
                    value={updatedMember.allergies}
                    onChange={(e, value) => setField("allergies", value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t(`members.allergies`)}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          )}
          <Box mt={4}>
            <Typography variant="h6">{t("members.addressInfo")}</Typography>
            <Box>
              <TextField
                id="address"
                value={updatedMember.address?.addressLine1}
                onChange={(e) =>
                  setField("address.addressLine1", e.target.value)
                }
                label={t("members.address")}
                required
                type="address"
                variant="outlined"
                fullWidth
                margin="normal"
              />
            </Box>
            <Box>
              <TextField
                id="address2"
                value={updatedMember.address?.addressLine2}
                onChange={(e) =>
                  setField("address.addressLine2", e.target.value)
                }
                label={t("members.address2")}
                variant="outlined"
                fullWidth
                margin="normal"
              />
            </Box>
            <Box className="flex md:flex-row flex-col gap-4">
              <Box className="flex-1">
                <TextField
                  id="city"
                  value={updatedMember.address?.city}
                  onChange={(e) => setField("address.city", e.target.value)}
                  label={t("members.city")}
                  required
                  variant="outlined"
                  fullWidth
                  placeholder="New York"
                  margin="normal"
                />
              </Box>
              <Box className="flex-1">
                <TextField
                  id="state"
                  value={updatedMember.address?.state}
                  onChange={(e) => setField("address.state", e.target.value)}
                  label={t("members.state")}
                  required
                  variant="outlined"
                  fullWidth
                  placeholder="California"
                  margin="normal"
                />
              </Box>
            </Box>
            <Box className="flex md:flex-row flex-col gap-4">
              <Box className="flex-1">
                <TextField
                  id="zip"
                  value={updatedMember.address?.zipcode}
                  onChange={(e) => setField("address.zipcode", e.target.value)}
                  label={t("members.zip")}
                  required
                  variant="outlined"
                  fullWidth
                  placeholder="94303"
                  margin="normal"
                />
              </Box>
              <Box className="flex-1">
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="country-label">
                    {t("members.country")}
                  </InputLabel>
                  <Select
                    labelId="country-label"
                    id="country"
                    label={t("members.country")}
                    value={updatedMember.address?.country}
                    placeholder={t(`countries.${CountryList.US}`)}
                    onChange={(e) =>
                      setField("address.country", e.target.value)
                    }
                  >
                    {Object.keys(CountryList).map((countryCode) => (
                      <MenuItem value={countryCode} key={countryCode}>
                        {t(`countries.${countryCode}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>

          <Box>
            <FormControl fullWidth margin="normal" disabled>
              <InputLabel id="membership-label">
                {t("membership.tier")}
              </InputLabel>
              <Select
                labelId="membership-label"
                id="membership"
                label={t("membership.tier")}
                value={updatedMember.membership}
                placeholder={t(`countries.${Memberships.premium}`)}
                onChange={(e) => setField("membership", e.target.value)}
              >
                {Object.entries(Memberships).map(([key, price]) => (
                  <MenuItem value={price} key={key}>
                    {t(`membership.tiers.${key}`, { price })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Button
            variant="contained"
            type="submit"
            fullWidth
            sx={{ my: 4 }}
            disabled={isLoading}
          >
            {t(member.uuid ? "members.update" : "members.create")}
          </Button>
        </form>
      </Container>
    </Box>
  );

  return (
    <div>
      <Drawer open={isOpen} onClose={toggleDrawer(false)} anchor="right">
        {DrawerContent}
      </Drawer>
    </div>
  );
}
